import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {UserAbsenceRequest} from "../../api/staff";
import FormRow from "../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import useAbsenceApprovalForm from "./Hooks/useAbsenceApprovalForm";
import FormActionContainer from "../Form/FormActionContainer";
import {Loading} from "store-fetch-wrappers";
import {Approval} from "./AbsenceApproval";

function AbsenceApprovalForm({absenceRequest, onFormClosed, approvalType}: Props) {
    const {response, setResponse, userAbsenceStore, resolveAbsenceApprovalResponse} =
        useAbsenceApprovalForm(absenceRequest);
    return (
        <React.Fragment>
            <FormRow rowName={"Reason"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    value={response.reason}
                    onChange={(e) => {
                        const {value} = e.target;

                        setResponse({
                            ...response,
                            reason: value.length === 0 ? undefined : value
                        });
                    }}
                    debounceTimeout={150}
                    className="input-fields"
                    placeholder={"Enter reason here..."}
                />
            </FormRow>
            {approvalType === Approval.Accept ? (
                <FormRow rowName={"Duration (Days)"} columnDetailClassName={"pl-0 pr-0"}>
                    <input
                        value={response.numberDays}
                        onChange={(e) => {
                            const {value} = e.target;

                            setResponse({
                                ...response,
                                numberDays: +value
                            });
                        }}
                        className="input-fields"
                        type={"number"}
                        placeholder={"Enter number of days..."}
                    />
                </FormRow>
            ) : null}
            <FormActionContainer>
                <React.Fragment>
                    {userAbsenceStore.loading ? (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    ) : (
                        <React.Fragment>
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Confirm"}
                                onClick={async () => {
                                    const success = await resolveAbsenceApprovalResponse(
                                        response,
                                        approvalType
                                    );
                                    if (!success) return;

                                    onFormClosed();
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={onFormClosed}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
}

export default AbsenceApprovalForm;

interface Props {
    absenceRequest: UserAbsenceRequest;
    onFormClosed: () => void;
    approvalType: Approval;
}
