import {useEffect, useState} from "react";
import {UserAbsenceInitialRequest} from "../../../../api/staff";
import moment from "moment";
import {showErrorToast} from "../../../../utils/toastUtils";
import {useDispatch, useSelector} from "react-redux";
import {
    nullifyRequestTimeOffAbsenceStore,
    saveAbsenceRequest
} from "../../../../store/requestAbsence/actions/RequestAbsenceActions";
import {RootStore} from "../../../../store/Store";
import {useAbsenceHooks} from "../../../Hooks/useAbsenceHooks";
import {useUserAbsence} from "../../../Hooks/useUserAbsence";

export function useRequestTimeOffForm() {
    const dispatch = useDispatch();
    const {getNumberOfDaysBetweenDays} = useUserAbsence();

    const requestAbsenceStore = useSelector((state: RootStore) => state.requestAbsenceStore);
    const [request, setRequest] = useState<UserAbsenceInitialRequest>({
        reason: "",
        startDate: moment().startOf("day").unix(),
        endDate: moment().add(1, "day").endOf("day").unix()
    });
    const {backToAbsenceCalendar} = useAbsenceHooks();

    useEffect(() => {
        return function () {
            dispatch(nullifyRequestTimeOffAbsenceStore());
        };
    }, []);

    function updateRequest(entity: UserAbsenceInitialRequest) {
        setRequest(entity);
    }

    async function save(): Promise<boolean> {
        const valid = validate();

        if (!valid) return false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return await dispatch(saveAbsenceRequest(request));
    }

    function validate(): boolean {
        if (request.startDate > request.endDate) {
            showErrorToast("Absence start time cannot be after absence end date");
            return false;
        }

        return true;
    }

    function getAbsenceLength() {
        const diff = getNumberOfDaysBetweenDays(request.startDate, request.endDate);
        const rounded = Math.round(diff);
        return `${rounded} day(s)`;
    }

    return {
        request,
        updateRequest,
        save,
        store: requestAbsenceStore,
        backToAbsenceCalendar,
        getAbsenceLength
    };
}
