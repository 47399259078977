import {UserAbsenceRequest} from "../../../../api/staff";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";

export function useHolidayRequest() {
    const userAbsenceStore = useSelector((state: RootStore) => state.absence);
    function goToConfigureStaffAbsenceConfig(item: UserAbsenceRequest) {
        const staffManagementUrl = process.env.REACT_APP_STAFF_MANAGEMENT;

        const path = `${staffManagementUrl}annual-leave/configure/${item.username}`;
        window.open(path, "_");
    }

    return {
        userAbsenceStore,
        goToConfigureStaffAbsenceConfig
    };
}
