import React from "react";
import {useAbsenceStatsPage} from "./Hooks/useAbsenceStatsPage";
import {WithServiceState} from "store-fetch-wrappers";
import AbsenceStatsWrapper from "./Components/AbsenceStatsWrapper";
import BackgroundContainer from "../../Containers/BackgroundContainer";

const ServiceWrapper = WithServiceState(AbsenceStatsWrapper);

function AbsenceStats() {
    const {absenceStatsStore} = useAbsenceStatsPage();
    return (
        <React.Fragment>
            <BackgroundContainer background={"background-stars"}>
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...absenceStatsStore}
                />
            </BackgroundContainer>
        </React.Fragment>
    );
}
export default AbsenceStats;
