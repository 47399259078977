import React from "react";
import {HolidayView} from "../Pages/HolidayList/Components/HolidayListActions";
import {IconType} from "../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import TableItemAction from "../Table/TableItemAction";

function ApproveAbsenceFormButton({
    view,
    icon,
    tooltipText,
    innerValue,
    buttonColour,
    onClick
}: Props) {
    return (
        <React.Fragment>
            {view === HolidayView.Calendar && (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={innerValue || ""}
                    onClick={onClick}
                    colour={buttonColour || ButtonColourOptions.Yellow}
                    roundedCorner
                />
            )}
            {view === HolidayView.List && (
                <TableItemAction
                    icon={icon || IconType.ThumbsUp}
                    tooltipText={tooltipText || ""}
                    onClick={onClick}
                />
            )}
        </React.Fragment>
    );
}

export default ApproveAbsenceFormButton;

interface Props {
    view: HolidayView;
    icon?: IconType;
    tooltipText?: string;
    innerValue?: string;
    buttonColour?: ButtonColourOptions;
    onClick: () => void;
}
