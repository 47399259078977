import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {handleUpdatedCredentials, login} from "../../../../store/auth/actions/AuthActions";
import {useHistory} from "react-router-dom";
import {CustomLoadingWheel} from "react-state-helpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {StaffAccessLevel} from "../../../../api/staff";
import {routeNames} from "../../../Navigation/routeNames";
import useMcConfig from "../../../Hooks/useMcConfig";
import {RootStore} from "../../../../store/Store";
import {getConfig} from "../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userStore = useSelector((state: RootStore) => state.auth);
    const currentRoute = localStorage.getItem("current_route");
    const {config} = useMcConfig();
    useEffect(() => {
        dispatch(setNavigationItems([]));
        (async function validateSession() {
            const creds = await handleUpdatedCredentials();

            //If there is no current session, force the user to login.
            if (!creds) {
                loginToSystem();
                return;
            }

            const user = userStore.data;
            //User doesn't exist, force the user to login.
            if (!user) {
                loginToSystem();
                return;
            }

            //Acts like a redirect if they were logged out due to inactivity.
            if (currentRoute) {
                history.push(currentRoute);
                return;
            }

            const mcConfig = await getConfig();
            const accessLevel = getUserAccessLevel(user, mcConfig);

            switch (accessLevel) {
                case StaffAccessLevel.SystemAdministrator:
                    history.push(routeNames.absenceOverview.path);
                    break;

                case StaffAccessLevel.DutyManager:
                case StaffAccessLevel.Staff:
                    history.push(routeNames.holidayRequestAbsenceList.path);
                    break;
            }
        })();
    }, []);

    const loginToSystem = () => {
        dispatch(login());
    };

    return (
        <div className="page background-stars">
            <h3 className="text-center title mt-5 pt-5 header-font">
                Loading {config.systemDetails.defaultName}...
            </h3>
            <CustomLoadingWheel />
            <div className="mt-5 text-center">
                <strong className="header-font">Checking Your Credentials</strong>
            </div>
            <p className="mt-4 text-center body-font">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Please wait while your credentials are checked. If you aren't redirected promptly,{" "}
                <br></br>
                click the button below <br></br>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Click here"
                    className="mt-4"
                    onClick={loginToSystem}
                    colour={ButtonColourOptions.Yellow}
                />
            </p>
        </div>
    );
};

export default Login;
