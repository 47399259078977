import React, {useEffect, useState} from "react";
import {EventClickArg} from "@fullcalendar/react";
import {AbsenceCalendarViewProps} from "../Components/AbsenceCalendarView";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../api/staff";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {useAbsenceHooks} from "../../../Hooks/useAbsenceHooks";
import useUnixUtils from "../../../Hooks/useUnixUtils";
import moment from "moment";
import {formatUnixToMMMMYYYY} from "../../../../utils/momentUtils";
import {useQuery} from "../../../Hooks/useQuery";
import {useFullCalendar} from "../../../FullCalendar/Hooks/useFullCalendar";
import useMcConfig from "../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

export function useAbsenceCalendarView({initialDate, response}: AbsenceCalendarViewProps) {
    //Full Calendar
    const {renderEventContent} = useFullCalendar();
    const calendarRef = React.createRef<any>();
    const history = useHistory();
    const user = useUserData();
    const query = useQuery();
    const {fullPath, searchQueries} = usePageUrl();
    const {createAbsence, requestHoliday} = useAbsenceHooks();
    const {dateToMoment} = useUnixUtils();
    const [showPreviousMonth, setShowPreviousMonth] = useState<boolean>(false);
    const [showNextMonth, setShowNextMonth] = useState<boolean>(false);
    const [previousMonthText, setPreviousMonthText] = useState<string>();
    const [nextMonthText, setNextMonthText] = useState<string>();
    const [currentMonthText, setCurrentMonthText] = useState<string>();
    const [currentMonth, setCurrentMonth] = useState<number>(-1);
    const [initialised, setInitialised] = useState<boolean>(false);
    const {config} = useMcConfig();

    useEffect(() => {
        const monthQuery = query.get("month");
        setCurrentMonth(monthQuery ? +monthQuery : -1);
    }, []);

    useEffect(() => {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        //FullCalendarApi works in ms, not s
        calendarApi.gotoDate(+initialDate * 1000);
    }, [initialDate]);

    useEffect(() => {
        if (!calendarRef.current) return;
        updateButtonState();
    }, [calendarRef.current]);

    useEffect(() => {
        if (initialised) return;
        if (!calendarRef.current) return;
        if (currentMonth < 0) return;
        if (!response) return;

        const calendarApi = calendarRef.current.getApi();
        const year = getYearFromResponse();
        if (!year) return;
        if (year > -1) {
            //
            const targetDate = moment().set({year, month: +currentMonth});
            calendarApi.gotoDate(targetDate.unix() * 1000);
            updateButtonState();
            setInitialised(true);
        }
    }, [calendarRef.current, currentMonth, response, initialised]);

    function viewAbsence(args: EventClickArg) {
        const calendarApi = calendarRef.current.getApi();
        const accessLevel = getUserAccessLevel(user, config);
        if (accessLevel !== StaffAccessLevel.SystemAdministrator) {
            calendarApi.unselect();
            return;
        }
        localStorage.setItem("absence_redirect", fullPath);

        const {id} = args.event;
        history.push({
            pathname: `${routeNames.editAbsence.path}/${id}`
        });
    }

    function isYearViewAtYearEnd() {
        const month = getMonthFromFullCalendar();
        return month === 11;
    }

    function isYearViewAtYearStart() {
        const month = getMonthFromFullCalendar();
        return month === 0;
    }

    function getMonthFromFullCalendar(): number {
        const calendarApi = calendarRef.current.getApi();
        const currentDate = calendarApi.getDate();

        return dateToMoment(currentDate).month();
    }

    function nextMonth(): void {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        const {month, year} = getMonthAndYear();
        if (month === 11) return;
        const currentDate = moment().set({year: year, month: month + 1});
        calendarApi.gotoDate(+currentDate.unix() * 1000);

        updateButtonState();
        updateUrlQuery(month + 1);
    }

    function updateUrlQuery(targetMonth: number) {
        const prefixQuery = searchQueries.split("&month")[0];
        history.push({
            search: `${prefixQuery}&month=${targetMonth}`
        });
    }

    function previousMonth(): void {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        const {month, year} = getMonthAndYear();
        if (month === 0) return;
        const currentDate = moment().set({year: year, month: month - 1});
        calendarApi.gotoDate(+currentDate.unix() * 1000);

        updateButtonState();
        updateUrlQuery(month - 1);
    }

    function getNextMonthName() {
        if (!calendarRef.current) return;
        const {month, year} = getMonthAndYear();
        //Will be hidden
        if (month === 11) return;
        const currentDate = moment().set({year: year, month: month + 1});

        return formatUnixToMMMMYYYY(currentDate.unix());
    }

    function getPreviousMonthName() {
        if (!calendarRef.current) return;
        const {month, year} = getMonthAndYear();
        //Will be hidden
        if (month === 0) return;
        const currentDate = moment().set({year: year, month: month - 1});

        return formatUnixToMMMMYYYY(currentDate.unix());
    }

    function getYearFromResponse(): number {
        if (!response.years) return -1;

        return response.years[0];
    }

    function updateButtonState() {
        setShowPreviousMonth(isYearViewAtYearStart());
        setShowNextMonth(isYearViewAtYearEnd());
        setPreviousMonthText(getPreviousMonthName());
        setNextMonthText(getNextMonthName());
        setCurrentMonthText(getCurrentMonthName());
    }

    function getMonthAndYear(): MonthYear {
        return {
            month: getMonthFromFullCalendar(),
            year: getYearFromResponse()
        };
    }
    function getCurrentMonthName() {
        const {month, year} = getMonthAndYear();
        const currentDate = moment().set({year, month});

        return formatUnixToMMMMYYYY(currentDate.unix());
    }

    return {
        calendarRef,
        viewAbsence,
        renderEventContent,
        requestHoliday,
        createAbsence,
        isJanuary: showPreviousMonth,
        isDecember: showNextMonth,
        previousMonth,
        nextMonth,
        previousMonthText,
        nextMonthText,
        currentMonthText
    };
}

interface MonthYear {
    month: number;
    year: number;
}
