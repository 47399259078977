import React from "react";

const BackgroundContainer = ({children, background}: BackgroundContainerProps) => {
    return (
        <div className={background}>
            <div className="background-overlay">
                <div className="page-container">{children}</div>
            </div>
        </div>
    );
};

export default BackgroundContainer;

interface BackgroundContainerProps {
    children: JSX.Element | JSX.Element[] | string;
    background: Backgrounds;
}

type Backgrounds = "background-chevrons" | "background-stars" | "background-clear";
