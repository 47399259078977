import React from "react";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "./MCButton";

/** Local action button. Contains a modal which will appear upon clicking. Confirmation action performed on confirm */
const ButtonWithActionConfirmation = ({
    action,
    buttonText,
    itemName,
    buttonColour,
    modalTitle
}: LocalDeleteActionButtonProps) => {
    const {isShown, toggle} = useModal();

    const onConfirm = () => {
        action();
        toggle();
    };

    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue={buttonText}
                onClick={toggle}
                colour={buttonColour}
                roundedCorner
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={modalTitle}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p>Are you sure you want to delete {itemName}?</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={onConfirm}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default ButtonWithActionConfirmation;

interface LocalDeleteActionButtonProps {
    buttonText: string;
    action: () => void;
    itemName: string;
    buttonColour: ButtonColourOptions;
    modalTitle: string;
}
