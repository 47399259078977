import React from "react";
import DateFilter from "../../../DateFilter/DateFilter";
import {useDispatch} from "react-redux";
import {useRequestFilters} from "../../../Filters/Hooks/useRequestFilters";
import {getAbsenceStatsForDashboard} from "../../../../store/absenceStats/actions/AbsenceStatsActions";
import {AbsenceStatsBlock} from "../../../../store/absenceStats/actions/AbsenceStatsActionTypes";
import StatsItem from "../../../Statistics/StatsItem";
import NoItemsMessage from "../../../Table/NoItemsMessage";

function AbsenceStatsWrapper(props: AbsenceStatsBlock) {
    const dispatch = useDispatch();
    const {onRequestChanged} = useRequestFilters();
    return (
        <React.Fragment>
            <div className="row pt-3 ml-0 mr-0">
                <div className="live-tracker-header-item mr-auto">
                    <h6>
                        <b className="container-info-title header-font">
                            Leave Management Compliance
                        </b>
                    </h6>
                </div>
                <div className="live-tracker-header-item">
                    <DateFilter
                        displayType={"Small"}
                        onChange={(req) => {
                            onRequestChanged(req, () => dispatch(getAbsenceStatsForDashboard(req)));
                        }}
                    />
                </div>
            </div>

            {props.statItems.length > 0 ? (
                <div className="stats-grid">
                    {props.statItems.map((item, key) => {
                        return <StatsItem {...item} key={key} />;
                    })}
                </div>
            ) : (
                <NoItemsMessage message={"There are no stats for this month"} />
            )}
        </React.Fragment>
    );
}

export default AbsenceStatsWrapper;
