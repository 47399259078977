import {useHistory} from "react-router-dom";
import {routeNames} from "../Navigation/routeNames";
import {usePageUrl} from "./usePageUrl";

export function useAbsenceHooks() {
    const history = useHistory();
    const {fullPath} = usePageUrl();

    function requestHoliday() {
        localStorage.setItem("absence_redirect", fullPath);
        history.push({
            pathname: routeNames.requestAbsence.path
        });
    }

    function createAbsence() {
        localStorage.setItem("absence_redirect", fullPath);
        history.push({
            pathname: routeNames.createAbsence.path
        });
    }

    function backToAbsenceCalendar(): void {
        const backPath = localStorage.getItem("absence_redirect");
        if (backPath) {
            history.push(backPath);
            return;
        }

        history.push({
            pathname: routeNames.holidayRequestAbsenceList.path
        });
    }

    return {
        createAbsence,
        requestHoliday,
        backToAbsenceCalendar
    };
}
