import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {UserAbsence} from "../../../api/staff";

export const ABSENCE_STORE = createStoreState("absence");

interface AbsenceLoading extends StoreServiceData<UserAbsence> {
    type: typeof ABSENCE_STORE.LOADING;
}

interface AbsenceError extends StoreServiceData<UserAbsence> {
    type: typeof ABSENCE_STORE.ERROR;
}

interface AbsenceSuccess extends StoreServiceData<UserAbsence> {
    type: typeof ABSENCE_STORE.SUCCESS;
}

export type AbsenceDispatchTypes = AbsenceLoading | AbsenceError | AbsenceSuccess;
