import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useEffect} from "react";
import {useRouteSetup} from "../../Hooks/useRouteSetup";
import {useUserData} from "../../../Hooks/useUserData";
import useMcConfig from "../../../Hooks/useMcConfig";

export function useAbsenceStatsPage() {
    const absenceStatsStore = useSelector((state: RootStore) => state.absenceStats);
    const {setRoutes} = useRouteSetup();
    const user = useUserData();
    const {config} = useMcConfig();

    useEffect(() => {
        if (!config.auth) return;
        if (user.username.length === 0) return;
        setRoutes(user, config);
    }, [user, config]);

    return {
        absenceStatsStore
    };
}
