import {StaffAccessLevel, UserData} from "../../../api/staff";
import {DDProps, sortUserDataToDropdownProps, StaffLink} from "../Helpers/dropdownUtils";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {SingleValue} from "react-select";

export function useStaffDropdown({username, onChange, accessLevelFilter}: PulseStaffDropdown) {
    const [staffOptions, setStaffOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | null>();

    const staffListStore = useSelector((state: RootStore) => state.staffList);

    /** Get the staff list upon mounting */
    useEffect(() => {
        if (!staffListStore.data) return;
        processStaffListIncoming(getFilteredStaffList(staffListStore.data));
    }, [staffListStore]);

    //Watch for these two items, set selected option BUT NO on change should be called here.
    useEffect(() => {
        if (!username) return;
        if (staffOptions.length === 0) return;

        const option = staffOptions.find((item) => item.value === username);

        setSelectedOption(option);
    }, [username, staffOptions]);

    const getFilteredStaffList = (staffList: UserData[]) => {
        if (accessLevelFilter) {
            return staffList.filter((item: UserData) => item.accessLevel === accessLevelFilter);
        }
        return staffList;
    };

    /** Sort the staff list into dropdown props */
    const processStaffListIncoming = (users: UserData[]) => {
        const options = sortUserDataToDropdownProps(users);
        setStaffOptions(options);
    };

    /** Fired when a new option is selected */
    const handleStaffChange = (newValue: SingleValue<DDProps>) => {
        setSelectedOption(newValue);
        const staffLinkOption = getStaffLinkFromDDProps(newValue);
        onChange(staffLinkOption);
    };

    function getStaffLinkFromDDProps(entity: SingleValue<DDProps>): StaffLink | null {
        if (!entity) return null;

        return {
            staffId: entity.value.toString(),
            staffName: entity.label
        };
    }

    return {
        staffOptions,
        selectedOption,
        handleStaffChange,
        staffListStore
    };
}

export interface PulseStaffDropdown {
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    onChange: (staffMember: StaffLink | null) => void;
    clearable: boolean;
    username?: string;
    disabled: boolean;
}
