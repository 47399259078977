import {AbsenceYearlyReport, UserAbsenceInfo} from "./useAbsenceReportDownload";

export function useYearlyAbsenceReportCSV() {
    function generateDownloadableCSVForReport(report: AbsenceYearlyReport) {
        const values: any[] = [];
        for (const monthInfo of report.monthAbsences) {
            values.push([monthInfo.name, "", "", "", "", "", ""]);

            for (const group of monthInfo.groupings) {
                values.push([group.type]);
                values.push([...userAbsenceHeadings()]);
                for (const absence of group.absences) {
                    values.push([...getValuesFromUserAbsence(absence)]);
                }
                values.push([""]);
            }
        }

        return values;
    }

    function getValuesFromUserAbsence(info: UserAbsenceInfo | undefined) {
        if (!info) {
            return ["", "", "", "", "", "", ""];
        }
        const {name, reason, absenceDuration, type, end, start} = info;
        return ["", name, type, reason, start, end, absenceDuration.toString()];
    }

    function userAbsenceHeadings(): string[] {
        return ["", "Name", "Type", "Reason", "Start", "End", "Absence Duration"];
    }

    return {
        generateDownloadableCSVForReport
    };
}
