import React from "react";
import {StaffAccessLevel, UserAbsenceRequestStatus} from "../../../../api/staff";
import {PulseTable} from "pulse_table";
import {useHolidayListTable} from "../Hooks/useHolidayListTable";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import FormHeader from "../../../Form/FormHeader";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {IconType} from "../../../Icon/Icon";
import {Loading} from "store-fetch-wrappers";
import {SortedListHolidayResponse} from "../../../../store/holidayRequestAbsenceList/actions/HolidayRequestAbsenceListActionTypes";
import AbsenceApproval, {Approval} from "../../../AbsenceApprovalForm/AbsenceApproval";
import {HolidayView} from "./HolidayListActions";
import TableItemAction from "../../../Table/TableItemAction";

function HolidayListTable(props: SortedListHolidayResponse) {
    const {headers, items, userAbsenceStore, goToConfigureStaffAbsenceConfig} =
        useHolidayListTable(props);
    return (
        <React.Fragment>
            <div className="mt-4">
                <FormHeader headerName={"Annual Leave Awaiting Approval"} />
                <PulseTable
                    items={items.filter(
                        (item) => item.actions?.status === UserAbsenceRequestStatus.PendingApproval
                    )}
                    headers={headers}
                    noItemsSection={
                        <NoItemsMessage message={"There are no pending holiday requests."} />
                    }
                    customRenderers={{
                        actions: (item) => (
                            <React.Fragment>
                                {userAbsenceStore.loading ? (
                                    <Loading type={"three-ring"} showLoadingText={false} />
                                ) : (
                                    <React.Fragment>
                                        <AuthAmI
                                            accessLevels={[StaffAccessLevel.SystemAdministrator]}
                                        >
                                            <TableItemAction
                                                tooltipText={`Configure absence config for ${item.name}`}
                                                onClick={() =>
                                                    goToConfigureStaffAbsenceConfig(item.actions)
                                                }
                                                icon={IconType.Cog}
                                            />
                                            <AbsenceApproval
                                                view={HolidayView.List}
                                                tooltipText={"Approve Annual Leave"}
                                                modalTitle={"Approve Annual Leave"}
                                                icon={IconType.ThumbsUp}
                                                absenceRequest={item.actions}
                                                approvalType={Approval.Accept}
                                            />
                                            <AbsenceApproval
                                                view={HolidayView.List}
                                                modalTitle={"Decline Annual Leave"}
                                                tooltipText={"Decline Annual Leave"}
                                                icon={IconType.ThumbsDown}
                                                absenceRequest={item.actions}
                                                approvalType={Approval.Reject}
                                            />
                                        </AuthAmI>
                                        <AuthAmI
                                            accessLevels={[
                                                StaffAccessLevel.DutyManager,
                                                StaffAccessLevel.Staff
                                            ]}
                                        >
                                            <p className="mb-0">N/A</p>
                                        </AuthAmI>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )
                    }}
                />
            </div>
            <div className="mt-4">
                <FormHeader headerName={"Approved Annual Leave"} />
                <PulseTable
                    items={items.filter(
                        (item) => item.actions?.status === UserAbsenceRequestStatus.Approved
                    )}
                    headers={headers}
                    noItemsSection={
                        <NoItemsMessage
                            message={"There are no approved annual leave requests here."}
                        />
                    }
                    customRenderers={{
                        actions: (item) => (
                            <React.Fragment>
                                <p className="mb-0">N/A</p>
                            </React.Fragment>
                        )
                    }}
                />
            </div>
            <div className="mt-4 mb-3">
                <FormHeader headerName={"Declined Annual Leave"} />
                <PulseTable
                    items={items.filter(
                        (item) => item.actions?.status === UserAbsenceRequestStatus.Rejected
                    )}
                    headers={headers}
                    noItemsSection={
                        <NoItemsMessage
                            message={"There are no declined annual leave requests here."}
                        />
                    }
                    customRenderers={{
                        actions: (item) => (
                            <React.Fragment>
                                <p className="mb-0">N/A</p>
                            </React.Fragment>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default HolidayListTable;
