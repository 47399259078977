import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import absenceReducer from "./absence/reducer/AbsenceReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import requestAbsenceReducer from "./requestAbsence/reducer/RequestAbsenceReducer";
import holidayRequestAbsenceListReducer from "./holidayRequestAbsenceList/reducer/HolidayRequestAbsenceListReducer";
import absenceCalendarReducer from "./absenceCalendar/reducer/AbsenceCalendarReducer";
import absenceStatsReducer from "./absenceStats/reducer/AbsenceStatsReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    absence: absenceReducer,
    absenceCalendar: absenceCalendarReducer,
    holidayRequestAbsenceList: holidayRequestAbsenceListReducer,
    requestAbsenceStore: requestAbsenceReducer,
    absenceStats: absenceStatsReducer,
    staffList: staffListReducer
});

export default RootReducer;
