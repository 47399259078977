import {Dispatch} from "redux";
import {
    ABSENCE_STATS_STORE,
    AbsenceStatsBlock,
    AbsenceStatsDispatchTypes
} from "./AbsenceStatsActionTypes";
import {
    AbsenceDashboardStatsRequest,
    AbsenceDashboardStatsResponse,
    AbsenceType
} from "../../../api/staff";
import {postDataToServiceWithData} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {generateBlankAbsenceStatsBlock} from "../reducer/AbsenceStatsReducer";
import {formatUnixToMMMMYYYY} from "../../../utils/momentUtils";
import {StatisticsItem} from "../../../components/Statistics/Helpers/statisticsHelpers";
import {showErrorToast} from "../../../utils/toastUtils";
import {
    getAngleForHalfPieChartFromPercentage,
    getFlooredPercentage
} from "../../../utils/mathUtils";

export function nullifyAbsenceStatsStore() {
    return async function (dispatch: Dispatch<AbsenceStatsDispatchTypes>) {
        dispatch({
            type: ABSENCE_STATS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: generateBlankAbsenceStatsBlock()
        });
    };
}

export function getAbsenceStatsForDashboard(request: AbsenceDashboardStatsRequest) {
    return async function (dispatch: Dispatch<AbsenceStatsDispatchTypes>) {
        try {
            const data = await postDataToServiceWithData(
                ABSENCE_STATS_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.getAbsenceDashStats(request),
                statusCodeCallback
            );

            if (!data) {
                showErrorToast("Could not fetch stats from service.");
                return;
            }

            dispatch({
                type: ABSENCE_STATS_STORE.SUCCESS,
                loading: false,
                error: null,
                data: sortStatsResponseToStatsBlock(data)
            });
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

export function sortStatsResponseToStatsBlock(
    resp: AbsenceDashboardStatsResponse
): AbsenceStatsBlock {
    const monthName = formatUnixToMMMMYYYY(resp.startDate);
    return {
        start: resp.startDate,
        end: resp.endDate,
        monthName,
        statItems: sortAbsenceCountsToStatsItem(resp.absenceCounts, monthName)
    };
}

export function sortAbsenceCountsToStatsItem(
    absenceCounts: {[key: string]: number},
    currentMonth: string
): StatisticsItem[] {
    const totalValue = getTotalAbsenceAmount(absenceCounts);
    const statistics = getUiNamedAbsenceCounts(absenceCounts);

    return statistics.map((item) => {
        const {absenceCount, absenceType} = item;

        // This is the actual value due to MC wanting more absences to be worse instead of better.
        const absenceCountDifference = totalValue - absenceCount;
        const percentage = getFlooredPercentage(absenceCountDifference, totalValue);

        // Pin percentage at 100 if there is no data because MC want it to default to green. Which is 100%
        const actualPercentage = totalValue === 0 ? 100 : percentage;
        return {
            tooltipText: getTooltipText(absenceType, absenceCount, totalValue, currentMonth),
            totalValue,
            needleAngle: +getAngleForHalfPieChartFromPercentage(actualPercentage).toFixed(2),
            outerLabel: `${absenceType} - (${currentMonth})`,
            compliancePercentage: actualPercentage
        };
    });
}

export function getUiNamedAbsenceCounts(absenceCounts: {
    [key: string]: number;
}): Array<AbsenceGroupedStat> {
    //type: Map<string, AbsenceGroupedStat>
    const statistics = getBlankAbsenceGroupedStats();

    for (const currentKey of Object.keys(absenceCounts)) {
        const absenceType = getAbsenceTypeFromString(currentKey);
        const absenceCount = absenceCounts[currentKey];

        switch (absenceType) {
            case AbsenceType.Unauthorised:
                updateGroupedStatistics(
                    statistics,
                    AbsenceStatsType.UnauthorisedLeave,
                    absenceCount
                );
                break;
            case AbsenceType.Holiday:
                updateGroupedStatistics(statistics, AbsenceStatsType.StaffHoliday, absenceCount);
                break;
            case AbsenceType.SickPaid:
            case AbsenceType.SickUnpaid:
                updateGroupedStatistics(statistics, AbsenceStatsType.StaffSickness, absenceCount);
                break;
        }
    }

    return Array.from(statistics.values());
}

function updateGroupedStatistics(
    statMap: Map<string, AbsenceGroupedStat>,
    absence: AbsenceStatsType,
    absenceCount: number
): void {
    const existing = statMap.get(absence);
    if (existing) {
        statMap.set(absence, {
            ...existing,
            absenceCount: existing.absenceCount + absenceCount
        });
    } else {
        statMap.set(absence, {
            absenceType: absence,
            absenceCount
        });
    }
}

function getTotalAbsenceAmount(counts: {[key: string]: number}): number {
    const keys = Object.keys(counts);
    let value = 0;
    for (const k of keys) {
        value += counts[k];
    }

    return value;
}

function getAbsenceTypeFromString(value: string): AbsenceType {
    return AbsenceType[value as keyof typeof AbsenceType];
}

function getTooltipText(
    currentKey: string,
    absenceCount: number,
    totalCount: number,
    currentMonth: string
): string {
    if (totalCount === 0) {
        const prefix = getGrammaticallyCorrectPrefix(currentKey);
        return `${prefix} for the month of ${currentMonth}`;
    }
    return `Number of absences for ${currentKey} for the month of ${currentMonth}: (${absenceCount} / ${totalCount})`;
}

function getGrammaticallyCorrectPrefix(currentKey: string): string {
    switch (currentKey) {
        case AbsenceStatsType.StaffSickness:
            return `There are no ${currentKey}'s`;
        case AbsenceStatsType.StaffHoliday:
            return `There are no ${currentKey}s`;
        case AbsenceStatsType.UnauthorisedLeave:
            return `There are no ${currentKey} absences`;

        default:
            return "";
    }
}

// eslint-disable-next-line no-shadow
export enum AbsenceStatsType {
    StaffHoliday = "Staff Holiday",
    StaffSickness = "Staff Sickness",
    UnauthorisedLeave = "Unauthorised Leave"
}

export interface AbsenceGroupedStat {
    absenceType: string;
    absenceCount: number;
}

function getBlankAbsenceGroupedStats(): Map<string, AbsenceGroupedStat> {
    const stats = new Map<string, AbsenceGroupedStat>();
    for (const value of Object.values(AbsenceStatsType)) {
        stats.set(value, {
            absenceType: value,
            absenceCount: 0
        });
    }
    return stats;
}
