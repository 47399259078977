import {useState} from "react";

export function useRequestFilters<T>() {
    const [request, setRequest] = useState<T>();

    function onRequestChanged(incRequest: T, func: () => void) {
        if (JSON.stringify(incRequest) === JSON.stringify(request)) return;
        setRequest(incRequest);

        func();
    }

    return {
        onRequestChanged
    };
}
