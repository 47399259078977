import React from "react";
import {nanoid} from "nanoid";
import Tooltip from "../Tooltip/Tooltip";

const DateSwitcher = ({showMonth, onChange}: DateSwitcherProps) => {
    const buttonId = nanoid();
    return (
        <React.Fragment>
            <Tooltip
                tooltipText={showMonth ? "Month View" : "Year View"}
                size={"md"}
                rootElement={"span"}
                place={"left"}
                theme={"dark"}
            >
                <button
                    data-tip
                    data-for={buttonId}
                    onClick={() => {
                        const toggled = (showMonth = !showMonth);

                        onChange(toggled);
                    }}
                    className="date-switcher-button font-weight-bolder"
                >
                    {showMonth ? "M" : "Y"}
                </button>
            </Tooltip>
        </React.Fragment>
    );
};

export default DateSwitcher;

interface DateSwitcherProps {
    showMonth: boolean;
    onChange: (value: boolean) => void;
}
