import {useEffect, useState} from "react";
import {AbsenceType, ListAbsencesRequest, StaffAccessLevel} from "../../../../api/staff";
import {useQuery} from "../../../Hooks/useQuery";
import {useHistory} from "react-router-dom";
import {useUserData} from "../../../Hooks/useUserData";
import moment from "moment";

export function useAbsenceCalendarFilters() {
    const [request, setRequest] = useState<ListAbsencesRequest>();
    const [showMonth, setShowMonth] = useState<boolean>(false);
    const query = useQuery();
    const history = useHistory();
    const user = useUserData();

    useEffect(() => {
        const year = query.get("year");
        const username = query.get("username");
        const types = query.get("types");
        const startDate = query.get("startDate");
        const endDate = query.get("endDate");

        const monthNumber = getCurrentMonthNumber();

        updateRequest(
            {
                years: year ? [+year] : undefined,
                types: types ? [getAbsenceTypeFromString(types)] : undefined,
                username: username || undefined,
                startDate: startDate ? +startDate : undefined,
                endDate: endDate ? +endDate : undefined
            },
            monthNumber.toString()
        );
    }, []);

    function getCurrentMonthNumber() {
        const month = moment().get("month");
        const monthNumber = query.get("month");

        if (!monthNumber) return month;

        return monthNumber === "undefined" ? month : monthNumber;
    }

    function updateRequest(newReq: ListAbsencesRequest, incMonth?: string | null) {
        const updatedRequest = updateBlankRequest(newReq);
        setShowMonth(!!updatedRequest.startDate);

        setRequest(updatedRequest);
        const monthNumber = getCurrentMonthNumber();
        buildUrlRequest(updatedRequest, monthNumber.toString());
    }

    //When we initially access page with no queries, this will ensure at least one property
    //is populated. This defaults to selecting the year.
    function updateBlankRequest(entity: ListAbsencesRequest): ListAbsencesRequest {
        if (!entity.years && !entity.startDate && !entity.endDate) {
            return {
                ...entity,
                years: [moment().year()]
            };
        }

        return entity;
    }

    function buildUrlRequest(incRequest: ListAbsencesRequest, incMonth?: string | null) {
        const search = getQueryStringsForUser(incRequest, incMonth);

        history.push({
            search: `?${search}`
        });
    }

    function getQueryStringsForUser(incRequest: ListAbsencesRequest, incMonth?: string | null) {
        const yearQuery = incRequest.years ? `year=${incRequest.years[0]}&month=${incMonth}` : "";
        const startEndQuery =
            incRequest.startDate && incRequest.endDate
                ? `startDate=${incRequest.startDate}&endDate=${incRequest.endDate}`
                : "";
        const typeQuery = incRequest.types ? `&types=${incRequest.types[0]}` : "";
        const usernameQuery = incRequest.username ? `&username=${incRequest.username}` : "";
        switch (user.accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return `${startEndQuery}${yearQuery}${typeQuery}${usernameQuery}`;
            default:
                return `${startEndQuery}${yearQuery}${typeQuery}`;
        }
    }

    function getAbsenceTypeFromString(value: string): AbsenceType {
        if (value === "AnnualLeave") {
            return AbsenceType.Holiday;
        }
        return AbsenceType[value as keyof typeof AbsenceType];
    }

    function getInitialYear(): number | undefined {
        if (!request) return;
        if (!request.years) return;

        return request.years[0];
    }

    return {
        request,
        updateRequest,
        getAbsenceTypeFromString,
        showMonth,
        getInitialYear
    };
}
