import {useDispatch} from "react-redux";
import {AbsenceType, UserAbsence} from "../../../../api/staff";
import {
    deleteAbsenceById,
    nullifyAbsenceStore,
    saveAbsence,
    setAbsence
} from "../../../../store/absence/actions/AbsenceActions";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import {useEffect} from "react";
import {useAbsenceHooks} from "../../../Hooks/useAbsenceHooks";
import {useUserAbsence} from "../../../Hooks/useUserAbsence";

export function useAbsenceForm() {
    const dispatch = useDispatch();
    const {getNumberOfDaysBetweenDays} = useUserAbsence();
    const {backToAbsenceCalendar} = useAbsenceHooks();

    useEffect(() => {
        return function () {
            dispatch(nullifyAbsenceStore());
        };
    }, []);

    function updateAbsence(entity: UserAbsence): void {
        dispatch(setAbsence(entity));
    }

    function getAbsenceTypeFromString(value: string): AbsenceType {
        if (value === "AnnualLeave") return AbsenceType.Holiday;
        return AbsenceType[value as keyof typeof AbsenceType];
    }

    async function save(entity: UserAbsence): Promise<boolean> {
        const valid = validate(entity);

        if (!valid) return false;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return await dispatch(saveAbsence(entity));
    }

    async function deleteAbsence(entity: UserAbsence): Promise<void> {
        if (!entity.id) {
            showErrorToast(
                "Absence does not contain ID. Critical issue. Contact System administrator"
            );
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteAbsenceById(entity.id));

        if (!success) {
            showErrorToast("Could not delete absence. Contact System Administrator");
            return;
        }

        showSuccessToast(`Deleted absence for ${entity.name} (${entity.type})`);
        backToAbsenceCalendar();
    }

    function validate(entity: UserAbsence): boolean {
        if (entity.username.length === 0) {
            showErrorToast("Staff Member must be selected to create an absence.");
            return false;
        }

        if (!entity.name) {
            showErrorToast("Staff Member must be selected to create an absence.");
            return false;
        }

        if (entity.startDate > entity.endDate) {
            showErrorToast("Absence start time cannot be after the absence end date");
            return false;
        }

        return true;
    }

    function getAbsenceLength(entity: UserAbsence) {
        const diff = getNumberOfDaysBetweenDays(entity.startDate, entity.endDate);
        return `${diff} day(s)`;
    }

    function getLeaveType(type: AbsenceType) {
        if (type === AbsenceType.Holiday) {
            return "AnnualLeave";
        }

        return type;
    }

    function getCorrectDeletionText(type: AbsenceType) {
        if (type === AbsenceType.Holiday) {
            return "Annual Leave";
        }
        return type;
    }

    return {
        updateAbsence,
        getAbsenceTypeFromString,
        save,
        backToAbsenceCalendar,
        getAbsenceLength,
        deleteAbsence,
        getLeaveType,
        getCorrectDeletionText
    };
}
