import {Dispatch} from "redux";
import {
    ABSENCE_CALENDAR_STORE,
    AbsenceCalendarDispatchTypes,
    CalendarAbsenceItem,
    SortedListAbsenceResponse
} from "./AbsenceCalendarActionTypes";
import {createBlankSortedListAbsenceResponse} from "../reducer/AbsenceCalendarReducer";
import {
    AbsenceType,
    ListAbsencesRequest,
    ListAbsencesResponse,
    UserAbsence
} from "../../../api/staff";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {deepCopy} from "../../../utils/copyUtils";
import moment from "moment";
import {
    darkBlueGradient,
    greenGradient,
    lightThemeWhite,
    orangeGradient,
    redGradient
} from "../../../utils/colourUtils";
import {formatUnixToDDMMYY} from "../../../utils/momentUtils";
import {showErrorToast} from "../../../utils/toastUtils";

export function nullifyAbsenceCalendarStore() {
    return async function (dispatch: Dispatch<AbsenceCalendarDispatchTypes>) {
        dispatch({
            type: ABSENCE_CALENDAR_STORE.SUCCESS,
            error: null,
            loading: false,
            data: createBlankSortedListAbsenceResponse()
        });
    };
}

export function getAbsencesForCalendar(request: ListAbsencesRequest) {
    return async function (dispatch: Dispatch<AbsenceCalendarDispatchTypes>) {
        try {
            const resp = await getDataFromServiceWithData(
                ABSENCE_CALENDAR_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.listAbsences(appendRequest(request)),
                statusCodeCallback
            );

            if (!resp) {
                showErrorToast("Could not get absences from the service");
                return;
            }

            dispatch({
                type: ABSENCE_CALENDAR_STORE.SUCCESS,
                error: null,
                loading: false,
                data: sortResponseToCalendarAbsenceResponse(resp)
            });
        } catch (e: any) {
            dispatch({
                type: ABSENCE_CALENDAR_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

function appendRequest(entity: ListAbsencesRequest): ListAbsencesRequest {
    if (entity.startDate && entity.endDate) {
        return {
            ...entity,
            startDate: moment.unix(entity.startDate).subtract(8, "days").unix(),
            endDate: moment.unix(entity.endDate).add(8, "days").unix()
        };
    }

    return entity;
}

export function sortResponseToCalendarAbsenceResponse(
    resp: ListAbsencesResponse
): SortedListAbsenceResponse {
    return {
        ...resp,
        sortedAbsenceItems: toAbsenceCalendarItem(resp.absences)
    };
}

function toAbsenceCalendarItem(results: UserAbsence[]): CalendarAbsenceItem[] {
    const copy = deepCopy(results);
    return copy.map((item) => {
        return {
            id: item.id,
            allDay: false,
            start: moment.unix(item.startDate).toDate(),
            end: moment.unix(item.endDate).toDate(),
            color: getCalendarItemColour(item),
            textColor: lightThemeWhite,
            title: getCalendarItemTitle(item),
            absenceType: item.type,
            isHistoric: isHistoric(item)
        };
    });
}

export function getCalendarItemColour(entity: UserAbsence): string {
    switch (entity.type) {
        case AbsenceType.Holiday:
            return darkBlueGradient;
        case AbsenceType.SickPaid:
            return greenGradient;
        case AbsenceType.SickUnpaid:
            return orangeGradient;
        case AbsenceType.Unauthorised:
            return redGradient;
    }
}

function getCalendarItemTitle(entity: UserAbsence): string {
    const {name, endDate, startDate, type} = entity;
    const start = formatUnixToDDMMYY(startDate);
    const end = formatUnixToDDMMYY(endDate);

    const uiCorrectType = type === AbsenceType.Holiday ? "Annual Leave" : type;

    return `${name} (${uiCorrectType}) (${start} - ${end})`;
}

function isHistoric(entity: UserAbsence): boolean {
    const now = moment().startOf("day");

    return entity.startDate < now.unix() && entity.endDate <= now.unix();
}
