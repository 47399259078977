import moment from "moment";
import useUnixUtils from "./useUnixUtils";

export function useUserAbsence() {
    const {toMoment} = useUnixUtils();

    function getNumberOfDaysBetweenDays(startDate: number, endDate: number): number {
        const start = toMoment(startDate);
        const end = toMoment(endDate);
        const diff = moment.duration(end.diff(start)).asDays();
        return Math.round(diff);
    }

    return {
        getNumberOfDaysBetweenDays
    };
}
