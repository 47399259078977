import {
    ABSENCE_CALENDAR_STORE,
    SortedListAbsenceResponse
} from "../actions/AbsenceCalendarActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<SortedListAbsenceResponse>(
    createBlankSortedListAbsenceResponse()
);

const absenceCalendarReducer = (
    state: StoreServiceData<SortedListAbsenceResponse> = defaultState,
    action: ServiceActionTypes<SortedListAbsenceResponse>
) => createReducer(state, action, ABSENCE_CALENDAR_STORE, () => showErrorToast(action.error));

export default absenceCalendarReducer;

export function createBlankSortedListAbsenceResponse(): SortedListAbsenceResponse {
    return {
        absences: [],
        sortedAbsenceItems: [],
        types: [],
        years: []
    };
}
