import {useEffect, useState} from "react";
import {useQuery} from "../../../Hooks/useQuery";
import {HolidayView} from "../Components/HolidayListActions";
import {IconType} from "../../../Icon/Icon";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {useHistory} from "react-router-dom";

export function useHolidayListActions() {
    const [view, setView] = useState<HolidayView>(HolidayView.List);
    const query = useQuery();
    const {searchQueries} = usePageUrl();
    const history = useHistory();

    useEffect(() => {
        const targetView = query.get("view");

        setView(targetView ? getHolidayViewFromString(targetView) : HolidayView.List);
    }, []);

    function updateView(currentView: HolidayView) {
        switch (currentView) {
            case HolidayView.Calendar:
                setView(HolidayView.List);
                updateUrlQuery(HolidayView.List);
                return;
            case HolidayView.List:
                setView(HolidayView.Calendar);
                updateUrlQuery(HolidayView.Calendar);
                return;
        }
    }

    function updateUrlQuery(targetView: HolidayView) {
        const prefixQuery = searchQueries.split("&view")[0];
        history.push({
            search: `${prefixQuery}&view=${targetView}`
        });
    }

    function getHolidayViewFromString(value: string): HolidayView {
        return HolidayView[value as keyof typeof HolidayView];
    }

    function getIconForButton() {
        switch (view) {
            case HolidayView.List:
                return IconType.List;
            case HolidayView.Calendar:
                return IconType.BlankCalendar;
        }
    }

    return {
        view,
        buttonIcon: getIconForButton(),
        updateView
    };
}
