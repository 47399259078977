import React, {useState} from "react";
import DatePickerInputButton from "../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";

function PulseYearPicker({onChange, initialYear, inline, minDate}: Props) {
    const [startDate, setStartDate] = useState<Moment | null>(
        initialYear ? moment().set({year: initialYear}) : moment()
    );
    return (
        <React.Fragment>
            <DatePicker
                selected={startDate?.toDate()}
                onChange={(value) => {
                    setStartDate(moment(value).startOf("year") || null);
                    onChange(value);
                }}
                showYearPicker
                dateFormat="yyyy"
                portalId="root-portal"
                customInput={<DatePickerInputButton />}
                inline={inline}
                minDate={minDate}
            />
        </React.Fragment>
    );
}

export default PulseYearPicker;

interface Props {
    onChange: (value: Date | null) => void;
    initialYear?: number;
    inline?: boolean;
    minDate?: Date | undefined | null;
}
