import React from "react";
import {DDProps} from "./Helpers/dropdownUtils";
import {Enum} from "../../utils/enumUtils";
import Select from "react-select";
import {usePulseEnumDropdown} from "./Hooks/usePulseEnumDropdown";

function PulseEnumDropdown(props: PulseDropdownProps) {
    const {options, handleOptionChanged, selectedOption} = usePulseEnumDropdown(props);
    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleOptionChanged}
                isSearchable={false}
                value={selectedOption}
                placeholder={props.placeholder}
                noOptionsMessage={() => "No Options Found"}
                isDisabled={props.disabled}
                isClearable={props.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
}

export default PulseEnumDropdown;

export interface PulseDropdownProps {
    value?: string | number;
    onChange: (newOption?: DDProps | null) => void;
    searchable: boolean;
    clearable: boolean;
    disabled: boolean;
    enumOptions: Enum<any>;
    placeholder?: string;
}
