import React from "react";
import {HolidayView} from "../Pages/HolidayList/Components/HolidayListActions";
import ApproveAbsenceFormButton from "./ApproveAbsenceFormButton";
import {Modal, useModal} from "pulse-modal";
import {IconType} from "../Icon/Icon";
import {ButtonColourOptions} from "../Button/MCButton";
import AbsenceApprovalForm from "./AbsenceApprovalForm";
import {UserAbsenceRequest} from "../../api/staff";

function AbsenceApproval({
    view,
    modalTitle,
    approvalType,
    onFormClosed,
    absenceRequest,
    ...rest
}: Props) {
    const {isShown, toggle} = useModal();
    return (
        <React.Fragment>
            <ApproveAbsenceFormButton view={view} {...rest} onClick={toggle} />
            <Modal
                modalSize={"sm"}
                onClose={toggle}
                isShown={isShown}
                bodyChildren={
                    <AbsenceApprovalForm
                        absenceRequest={absenceRequest}
                        approvalType={approvalType}
                        onFormClosed={() => {
                            toggle();
                            if (onFormClosed) {
                                onFormClosed();
                            }
                        }}
                    />
                }
                title={modalTitle}
            />
        </React.Fragment>
    );
}

export default AbsenceApproval;

interface Props {
    view: HolidayView;
    icon?: IconType;
    tooltipText?: string;
    innerValue?: string;
    modalTitle: string;
    absenceRequest: UserAbsenceRequest;
    buttonColour?: ButtonColourOptions;
    approvalType: Approval;
    onFormClosed?: () => void;
}

// eslint-disable-next-line no-shadow
export enum Approval {
    Accept = "Accept",
    Reject = "Reject"
}
