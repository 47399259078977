import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {
    HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE,
    SortedListHolidayResponse
} from "../actions/HolidayRequestAbsenceListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";
import {ListUserAbsenceRequestsResponse} from "../../../api/staff";

const defaultState = createDefaultStoreState<SortedListHolidayResponse>(
    createBlankListUserAbsenceRequestsResponse()
);

const holidayRequestAbsenceListReducer = (
    state: StoreServiceData<ListUserAbsenceRequestsResponse> = defaultState,
    action: ServiceActionTypes<ListUserAbsenceRequestsResponse>
) =>
    createReducer(state, action, HOLIDAY_REQUEST_ABSENCE_CALENDAR_STORE, () =>
        showErrorToast(action.error)
    );

export default holidayRequestAbsenceListReducer;

export function createBlankListUserAbsenceRequestsResponse(): SortedListHolidayResponse {
    return {
        startDate: 0,
        endDate: 0,
        requests: [],
        sortedHolidayItems: []
    };
}
