import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {UserAbsence} from "../../../api/staff";
import {ABSENCE_STORE} from "../actions/AbsenceActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UserAbsence>(null);

const absenceReducer = (
    state: StoreServiceData<UserAbsence> = defaultState,
    action: ServiceActionTypes<UserAbsence>
) => createReducer(state, action, ABSENCE_STORE, () => showErrorToast(action.error));

export default absenceReducer;
