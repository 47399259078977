import React, {useEffect} from "react";
import {RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {AbsenceType, ListAbsencesRequest, StaffAccessLevel} from "../../../../api/staff";
import {useAbsenceCalendarFilters} from "../Hooks/useAbsenceCalendarFilters";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import StaffDropdown from "../../../Dropdown/StaffDropdown";
import PulseEnumDropdown from "../../../Dropdown/PulseEnumDropdown";
import DateSwitcher from "../../../DatePicker/DateSwitcher";
import PulseMonthPicker from "../../../DatePicker/PulseMonthPicker";
import PulseYearPicker from "../../../DatePicker/PulseYearPicker";
import useUnixUtils from "../../../Hooks/useUnixUtils";
import moment from "moment";

function AbsenceCalendarFilters({onRequestChanged}: RequestFilterProps<ListAbsencesRequest>) {
    const {request, updateRequest, getAbsenceTypeFromString, showMonth, getInitialYear} =
        useAbsenceCalendarFilters();
    const {dateToMoment} = useUnixUtils();

    useEffect(() => {
        if (!request) return;
        onRequestChanged(request);
    }, [request]);

    function getRequestType(types?: Array<AbsenceType>) {
        if (!types) return;

        if (types[0] === AbsenceType.Holiday) {
            return "AnnualLeave";
        }

        return types[0];
    }

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Leave Type</h6>
                    <PulseEnumDropdown
                        searchable={false}
                        enumOptions={AbsenceTypeFilters}
                        disabled={false}
                        placeholder={"Select Leave Type..."}
                        value={getRequestType(request?.types)}
                        clearable={true}
                        onChange={(option) => {
                            if (!option) {
                                updateRequest({
                                    ...request,
                                    types: undefined
                                });
                                return;
                            }

                            const type = getAbsenceTypeFromString(option.value.toString());

                            updateRequest({
                                ...request,
                                types: [type]
                            });
                        }}
                    />
                </div>
                <div className="filter-item">
                    <h6>Date</h6>
                    <div className="filter-item-inner-wrapper">
                        <div className="filter-item-inner">
                            {showMonth ? (
                                <PulseMonthPicker
                                    initialDate={request?.startDate}
                                    onChange={(item) => {
                                        if (!item) return;
                                        const start = dateToMoment(item).startOf("month").unix();
                                        const end = dateToMoment(item).endOf("month").unix();

                                        updateRequest({
                                            ...request,
                                            years: undefined,
                                            startDate: start,
                                            endDate: end
                                        });
                                    }}
                                />
                            ) : (
                                <PulseYearPicker
                                    initialYear={getInitialYear()}
                                    onChange={(item) => {
                                        if (!item) return;
                                        const year = dateToMoment(item).year();

                                        updateRequest({
                                            ...request,
                                            years: [year],
                                            startDate: undefined,
                                            endDate: undefined
                                        });
                                    }}
                                />
                            )}
                        </div>
                        <div className="filter-item-inner">
                            <DateSwitcher
                                showMonth={showMonth}
                                onChange={(value) => {
                                    const now = moment();
                                    if (value) {
                                        const start = now.clone().startOf("month").unix();
                                        const end = now.clone().endOf("month").unix();

                                        updateRequest(
                                            {
                                                ...request,
                                                years: undefined,
                                                startDate: start,
                                                endDate: end
                                            },
                                            now.get("month").toString()
                                        );

                                        return;
                                    }

                                    updateRequest(
                                        {
                                            ...request,
                                            years: [now.year()],
                                            startDate: undefined,
                                            endDate: undefined
                                        },
                                        now.get("month").toString()
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <div className="filter-item">
                        <h6>Staff Member</h6>
                        <StaffDropdown
                            searchable={true}
                            onChange={(staff) => {
                                updateRequest({
                                    ...request,
                                    username: staff ? staff.staffId : undefined
                                });
                            }}
                            clearable={true}
                            username={request?.username}
                            disabled={false}
                        />
                    </div>
                </AuthAmI>
            </div>
        </React.Fragment>
    );
}

export default AbsenceCalendarFilters;

// eslint-disable-next-line no-shadow
export enum AbsenceTypeFilters {
    AnnualLeave = "Holiday",
    SickPaid = "SickPaid",
    SickUnpaid = "SickUnpaid",
    Unauthorised = "Unauthorised"
}
