import {ListUserAbsenceRequestsResponse, UserAbsenceRequest} from "../../../../api/staff";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {useHolidayRequest} from "./useHolidayRequest";

export function useHolidayListTable(resp: ListUserAbsenceRequestsResponse) {
    const {userAbsenceStore, goToConfigureStaffAbsenceConfig} = useHolidayRequest();

    function tabledItems(): TableRow[] {
        if (!resp.requests) return [];

        return resp.requests.map((item) => {
            return {
                name: item.name,
                status: getUiFriendlyText(item.status),
                reason: item.reason,
                startDate: formatUnixToDDMMYYYY(item.startDate),
                endDate: formatUnixToDDMMYYYY(item.endDate),
                actions: item
            };
        });
    }

    function headers() {
        return {
            name: "Staff Member",
            status: "Status",
            reason: "Reason",
            startDate: "Holiday From",
            endDate: "Holiday To",
            actions: "Actions"
        };
    }

    return {
        items: tabledItems(),
        headers: headers(),
        userAbsenceStore,
        goToConfigureStaffAbsenceConfig
    };
}

interface TableRow {
    name: string;
    reason?: string;
    startDate: string;
    endDate: string;
    status: string;
    actions: UserAbsenceRequest;
}
