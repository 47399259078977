import {useEffect, useState} from "react";
import {DDProps} from "../Helpers/dropdownUtils";
import {PulseDropdownProps} from "../PulseEnumDropdown";
import {Enum} from "../../../utils/enumUtils";
import {splitStringByCapitalLetter} from "../../../utils/textUtils";
import {SingleValue} from "react-select";

export function usePulseEnumDropdown({enumOptions, value, onChange}: PulseDropdownProps) {
    const [options, setOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | null>();

    useEffect(() => {
        setOptions(getDropdownOptions(enumOptions));
    }, []);

    useEffect(() => {
        if (options.length === 0) return;
        if (!value) return;

        const option = options.find((item) => item.value === value);

        setSelectedOption(option);
    }, [value, options]);

    function getDropdownOptions(incEnumOptions: Enum<any>): DDProps[] {
        return Object.keys(enumOptions).map((item) => {
            return {
                label: splitStringByCapitalLetter(item),
                value: item
            };
        });
    }

    function handleOptionChanged(newValue: SingleValue<DDProps>) {
        onChange(newValue);
        setSelectedOption(newValue);
    }

    return {
        options,
        selectedOption,
        handleOptionChanged
    };
}
