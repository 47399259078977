import {useState} from "react";
import {DateRange} from "../../../utils/filterUtils";
import moment from "moment";

export function useDateFilter() {
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: moment().startOf("month").unix(),
        endDate: moment().endOf("month").unix()
    });

    function updateRange(newRange: DateRange) {
        setDateRange(newRange);
    }

    return {
        dateRange,
        updateRange
    };
}
