import {ClinicalGrade, StaffAccessLevel, UserData} from "../../api/staff";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";

export const useUserData = () => {
    const user: UserData = {
        flags: undefined,
        mfa: false,
        paye: false,
        postCode: "",
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        accessLevel: StaffAccessLevel.Staff,
        clinicalGrade: ClinicalGrade.None
    };

    const authStore = useSelector((state: RootStore) => state.auth);

    const userFromStore = authStore.data;

    if (userFromStore) {
        return userFromStore;
    }

    return user;
};
