import {capitalizeFirstLetter, splitStringByCapitalLetter} from "./textUtils";

/** Creates a csv file from JSON data. NOTE: JSON data needs to be formatted correctly */
export function jsonToCsv(filename: string, rows: any[]) {
    // if the object has no rows. stop the process here.
    if (!rows || !rows.length) {
        return;
    }

    /** Generate CSV here.
     *  use the ',' as a separator (New Line is JSON)
     *  if there are any dates in date format, regex the cell to stop it from breaking
     * */
    const separator = ",";
    const rawKeys = Object.keys(rows[0]);
    const keys: string[] = [];

    for (let i = 0; i < rawKeys.length; ++i) {
        const rowName = capitalizeFirstLetter(rawKeys[i]);
        keys.push(splitStringByCapitalLetter(rowName));
    }

    const csvContent =
        keys.join(separator) +
        "\n" +
        rows
            .map((row: any) => {
                return rawKeys
                    .map((k: string) => {
                        let cell = row[k] === null || row[k] === undefined ? "" : row[k];
                        cell =
                            cell instanceof Date
                                ? cell.toLocaleDateString()
                                : cell.toString().replace(/"/g, '""');
                        if (cell.search(/([",\n])/g) >= 0) {
                            cell = `"${cell}"`;
                        }
                        return cell;
                    })
                    .join(separator);
            })
            .join("\n");

    // Allows for download to appear in browser.
    const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
