import {Dispatch} from "redux";
import {ABSENCE_STORE, AbsenceDispatchTypes} from "./AbsenceActionTypes";
import {AbsenceApprovalResponse, AbsenceType, UserAbsence} from "../../../api/staff";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import moment from "moment";
import store from "../../Store";
import {getAllMedicareStaffMembers} from "../../staffList/actions/StaffListActions";

export function nullifyAbsenceStore() {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        dispatch({
            type: ABSENCE_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
}

export function createNewAbsence() {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllMedicareStaffMembers());

            dispatch({
                type: ABSENCE_STORE.SUCCESS,
                loading: false,
                error: null,
                data: createBlankAbsence()
            });
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

export function setAbsence(newAbsence: UserAbsence) {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        dispatch({
            type: ABSENCE_STORE.SUCCESS,
            loading: false,
            error: null,
            data: newAbsence
        });
    };
}

export function saveAbsence(absence: UserAbsence) {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                ABSENCE_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.saveAbsence(absence),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

export function getAbsenceById(id: number) {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                ABSENCE_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.getAbsence(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

export function deleteAbsenceById(id: number) {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                ABSENCE_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.deleteAbsence(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

export function acceptHoliday(response: AbsenceApprovalResponse) {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                ABSENCE_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.acceptAbsenceRequest(response),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

export function rejectHoliday(response: AbsenceApprovalResponse) {
    return async (dispatch: Dispatch<AbsenceDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                ABSENCE_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.rejectAbsenceRequest(response),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

function createBlankAbsence(): UserAbsence {
    const now = moment();
    const start = now.clone().startOf("day");
    const end = now.clone().add(1, "day");
    const numberDays = Math.round(moment.duration(end.diff(start)).asDays()) + 1;
    return {
        id: 0,
        name: "",
        numberDays,
        reason: "",
        startDate: start.unix(),
        endDate: end.unix(),
        type: AbsenceType.SickPaid,
        username: ""
    };
}
