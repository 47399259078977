import React, {useEffect} from "react";
import {useHolidayListFilters} from "../Hooks/useHolidayListFilters";
import {RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {ListUserAbsenceRequests, StaffAccessLevel} from "../../../../api/staff";
import useUnixUtils from "../../../Hooks/useUnixUtils";
import PulseMonthPicker from "../../../DatePicker/PulseMonthPicker";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import StaffDropdown from "../../../Dropdown/StaffDropdown";

function HolidayListFilters({onRequestChanged}: RequestFilterProps<ListUserAbsenceRequests>) {
    const {request, updateRequest, getUsernameFromRequest} = useHolidayListFilters();
    const {dateToMoment} = useUnixUtils();

    useEffect(() => {
        if (!request) return;
        onRequestChanged(request);
    }, [request]);

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Month</h6>
                    <PulseMonthPicker
                        initialDate={request?.startDate}
                        onChange={(newDate) => {
                            if (!newDate) return;
                            const start = dateToMoment(newDate).clone().startOf("month");
                            const end = dateToMoment(newDate).clone().endOf("month");
                            updateRequest({
                                ...request,
                                startDate: start.unix(),
                                endDate: end.unix()
                            });
                        }}
                    />
                </div>

                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <div className="filter-item">
                        <h6>Staff Member</h6>
                        {request ? (
                            <StaffDropdown
                                searchable={true}
                                onChange={(staff) => {
                                    if (!request) return;
                                    updateRequest({
                                        ...request,
                                        usernames: staff ? [staff.staffId] : undefined
                                    });
                                }}
                                clearable={true}
                                username={getUsernameFromRequest()}
                                disabled={false}
                            />
                        ) : null}
                    </div>
                </AuthAmI>
            </div>
        </React.Fragment>
    );
}

export default HolidayListFilters;
