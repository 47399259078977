import React from "react";
import barScale from "../../images/BarScale.png";
import barScaleNeedle from "../../images/BarScaleNeedle.png";
import {StatisticsItem} from "./Helpers/statisticsHelpers";
import Tooltip from "../Tooltip/Tooltip";
import {useStatistics} from "./Hooks/useStatistics";

const Bar = ({tooltipText, compliancePercentage}: StatisticsItem) => {
    const {getNeedleHeight} = useStatistics();
    return (
        <React.Fragment>
            <div className="d-inline-block mr-auto mobile-stats-container">
                <div className="row">
                    <div className="col d-flex position-relative justify-content-center">
                        <Tooltip
                            wrapperClassName="cursor-pointer"
                            tooltipText={tooltipText}
                            size={"lg"}
                            place={"right"}
                            theme={"dark"}
                        >
                            <img className="bar" src={barScale} alt="" />
                            <div
                                style={{
                                    transform: `translateY(${getNeedleHeight(
                                        compliancePercentage
                                    )}px)`
                                }}
                                className="bar-needle-parent"
                            >
                                <img className="bar-needle" src={barScaleNeedle} alt="" />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Bar;
