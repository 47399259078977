import moment from "moment";
import {formatUnixToLongMonthName} from "../../../utils/momentUtils";

export function useMonthInfo() {
    function getMonthStartAndEndTimestampForYear(year: number): MonthWithStartEnd[] {
        const startOfYear = moment().set({year}).startOf("year");
        const monthsInYear = 12;
        const monthInfo: MonthWithStartEnd[] = [];

        for (let i = 0; i < monthsInYear; ++i) {
            const newMonth = startOfYear.clone().add(i, "month");
            const start = newMonth.clone().startOf("month");
            const end = newMonth.clone().endOf("month");

            monthInfo.push({
                start: start.unix(),
                end: end.unix(),
                monthName: formatUnixToLongMonthName(newMonth.unix())
            });
        }
        return monthInfo;
    }

    return {
        getMonthStartAndEndTimestampForYear
    };
}

export interface MonthWithStartEnd {
    monthName: string;
    start: number;
    end: number;
}
