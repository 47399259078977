import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

function AbsenceCalendarYearActions({
    previousMonth,
    nextMonth,
    previousMonthText,
    nextMonthText,
    currentMonthText,
    isDecember,
    isJanuary
}: Props) {
    return (
        <React.Fragment>
            <div className="row mt-2 ml-0 mr-0">
                <div className="col d-inline-block pl-0">
                    {!isJanuary && (
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={previousMonthText || ""}
                            onClick={previousMonth}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    )}
                </div>
                <div className="col d-inline-block">
                    <h2 className="text-center">{currentMonthText}</h2>
                </div>
                <div className="col d-inline-block pr-0">
                    {!isDecember && (
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={nextMonthText || ""}
                            onClick={nextMonth}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                            className="float-right"
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default AbsenceCalendarYearActions;

interface Props {
    isJanuary: boolean;
    isDecember: boolean;
    previousMonthText: string;
    currentMonthText: string;
    nextMonthText: string;
    previousMonth: () => void;
    nextMonth: () => void;
}
