import {HolidayCalendarViewProps} from "../Components/HolidayListCalendarView";
import React, {useEffect, useState} from "react";
import {useFullCalendar} from "../../../FullCalendar/Hooks/useFullCalendar";
import {EventClickArg} from "@fullcalendar/react";
import {StaffAccessLevel, UserAbsenceRequest} from "../../../../api/staff";
import {useModal} from "pulse-modal";
import {useUserData} from "../../../Hooks/useUserData";

export function useHolidayListCalendarView({initialDate, response}: HolidayCalendarViewProps) {
    const {renderEventContent} = useFullCalendar();
    const calendarRef = React.createRef<any>();
    const [selectedRequest, setSelectedRequest] = useState<UserAbsenceRequest>();
    const {toggle, isShown} = useModal();
    const user = useUserData();

    useEffect(() => {
        if (!calendarRef.current) return;
        const calendarApi = calendarRef.current.getApi();
        //FullCalendarApi works in ms, not s
        calendarApi.gotoDate(+initialDate * 1000);
    }, [initialDate]);

    function viewHolidayRequest(args: EventClickArg) {
        if (user.username.length === 0) return;
        if (user.accessLevel !== StaffAccessLevel.SystemAdministrator) return;
        const {requests} = response;
        if (!requests) return;

        const foundItem = requests.find((item) => item.id === +args.event.id);

        if (!foundItem) return;

        setSelectedRequest(foundItem);
        toggle();
    }

    function onModalClose() {
        setSelectedRequest(undefined);
        toggle();
    }

    return {
        calendarRef,
        renderEventContent,
        viewHolidayRequest,
        selectedRequest,
        toggle,
        isShown,
        onModalClose
    };
}
