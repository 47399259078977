import React, {useEffect} from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import {useAbsenceHooks} from "../../../Hooks/useAbsenceHooks";
import {useHolidayListActions} from "../Hooks/useHolidayListActions";
import Icon from "../../../Icon/Icon";
import Tooltip from "../../../Tooltip/Tooltip";

function HolidayListActions({onViewChanged}: Props) {
    const {createAbsence, requestHoliday} = useAbsenceHooks();
    const {view, buttonIcon, updateView} = useHolidayListActions();

    useEffect(() => {
        onViewChanged(view);
    }, [view]);
    return (
        <div className="row mt-5 ml-0 mr-0">
            <div className="col d-flex justify-content-end pr-0">
                <Tooltip
                    theme={"dark"}
                    tooltipText={`This will toggle the view between list and calendar views. Current view: ${view.toUpperCase()}`}
                    size={"md"}
                    place={"left"}
                >
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={<Icon rootElement={"Span"} icon={buttonIcon} size={"Medium"} />}
                        onClick={() => {
                            updateView(view);
                        }}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </Tooltip>

                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Request Annual Leave"}
                    onClick={requestHoliday}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Create Leave"}
                        onClick={createAbsence}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </AuthAmI>
            </div>
        </div>
    );
}

export default HolidayListActions;

interface Props {
    onViewChanged: (newView: HolidayView) => void;
}

// eslint-disable-next-line no-shadow
export enum HolidayView {
    List = "List",
    Calendar = "Calendar"
}
