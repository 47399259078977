import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {ABSENCE_STATS_STORE, AbsenceStatsBlock} from "../actions/AbsenceStatsActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<AbsenceStatsBlock>(generateBlankAbsenceStatsBlock());

const absenceStatsReducer = (
    state: StoreServiceData<AbsenceStatsBlock> = defaultState,
    action: ServiceActionTypes<AbsenceStatsBlock>
) => createReducer(state, action, ABSENCE_STATS_STORE, () => showErrorToast(action.error));

export default absenceStatsReducer;

export function generateBlankAbsenceStatsBlock(): AbsenceStatsBlock {
    return {
        start: 0,
        end: 0,
        monthName: "",
        statItems: []
    };
}
