import moment, {Moment} from "moment";

function useUnixUtils() {
    // Converts Linux Epoch into a JS Date
    function toJsDate(unix: number): Date {
        return moment.unix(unix).toDate();
    }

    // Converts Linux Epoch into a Moment Object
    function toMoment(unix: number): Moment {
        return moment.unix(unix);
    }

    function dateToUnix(date: Date): number {
        return moment(date).unix();
    }

    function dateToMoment(date: Date): Moment {
        return moment(date);
    }

    function unixToDate(unix: number): Date {
        return moment.unix(unix).toDate();
    }

    return {
        toJsDate,
        toMoment,
        dateToUnix,
        dateToMoment,
        unixToDate
    };
}

export default useUnixUtils;
