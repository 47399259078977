import {useEffect, useState} from "react";
import {
    AbsenceApprovalResponse,
    ListUserAbsenceRequests,
    UserAbsenceRequest
} from "../../../api/staff";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {
    acceptHoliday,
    nullifyAbsenceStore,
    rejectHoliday
} from "../../../store/absence/actions/AbsenceActions";
import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import {getHolidayRequestsForCalendar} from "../../../store/holidayRequestAbsenceList/actions/HolidayRequestAbsenceListActions";
import moment from "moment";
import {useQuery} from "../../Hooks/useQuery";
import {Approval} from "../AbsenceApproval";
import {useUserAbsence} from "../../Hooks/useUserAbsence";

function useAbsenceApprovalForm(props: UserAbsenceRequest) {
    const [response, setResponse] = useState<AbsenceApprovalResponse>(props);
    const userAbsenceStore = useSelector((state: RootStore) => state.absence);
    const dispatch = useDispatch();
    const holidayListStore = useSelector((state: RootStore) => state.holidayRequestAbsenceList);
    const query = useQuery();
    const {getNumberOfDaysBetweenDays} = useUserAbsence();

    useEffect(() => {
        const numberDays = getNumberOfDaysBetweenDays(props.startDate, props.endDate);
        setResponse({
            id: props.id,
            reason: "",
            numberDays
        });
    }, [props]);

    function getQueriesForHolidayListRequest(): ListUserAbsenceRequests {
        const start = query.get("startDate");
        const end = query.get("endDate");
        const usernames = holidayListStore.data?.usernames;

        return {
            startDate: start ? +start : moment().startOf("month").unix(),
            endDate: end ? +end : moment().endOf("month").unix(),
            usernames: usernames ? usernames : undefined
        };
    }

    async function resolveAbsenceApprovalResponse(
        item: AbsenceApprovalResponse,
        approvalType: Approval
    ): Promise<boolean> {
        switch (approvalType) {
            case Approval.Accept:
                return await approveHolidayRequest(item);
            case Approval.Reject:
                return await rejectHolidayRequest(item);
        }
    }

    async function approveHolidayRequest(item: AbsenceApprovalResponse): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(acceptHoliday(item));

        if (!success) {
            showErrorToast(`Could not approve of holiday request.`);
            return false;
        }

        showSuccessToast(`Successfully approved holiday request.`);

        dispatch(nullifyAbsenceStore());
        dispatch(getHolidayRequestsForCalendar(getQueriesForHolidayListRequest()));
        return true;
    }

    async function rejectHolidayRequest(item: AbsenceApprovalResponse): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(rejectHoliday(item));

        if (!success) {
            showErrorToast(`Could not reject holiday request.`);
            return false;
        }

        showSuccessToast(`Successfully rejected holiday request.`);

        dispatch(nullifyAbsenceStore());
        dispatch(getHolidayRequestsForCalendar(getQueriesForHolidayListRequest()));
        return true;
    }

    return {
        setResponse,
        response,
        userAbsenceStore,
        resolveAbsenceApprovalResponse
    };
}

export default useAbsenceApprovalForm;
