import {getValueFromPercentageWithCustomTotal} from "../../../utils/mathUtils";

export function useStatistics() {
    function getNeedleHeight(percentage: number) {
        const innerHeight = 110;
        const percent = Math.floor(getValueFromPercentageWithCustomTotal(percentage, innerHeight));
        // Invert the value
        return -1 * percent;
    }
    return {
        getNeedleHeight
    };
}
