import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {createNewAbsence, getAbsenceById} from "../../../../store/absence/actions/AbsenceActions";
import {useRouteSetup} from "../../Hooks/useRouteSetup";
import {useUserData} from "../../../Hooks/useUserData";
import useMcConfig from "../../../Hooks/useMcConfig";

export function useAbsencePage() {
    const {config} = useMcConfig();
    const dispatch = useDispatch();
    const absenceStore = useSelector((state: RootStore) => state.absence);
    const params: any = useParams();
    const user = useUserData();
    const {setRoutes} = useRouteSetup();

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!config.auth) return;
        if (user.username.length === 0) return;
        setRoutes(user, config);
    }, [user, config]);

    function fetchData(): void {
        if (params.absenceId) {
            dispatch(getAbsenceById(+params.absenceId));
            return;
        }

        dispatch(createNewAbsence());
    }

    return {
        store: absenceStore
    };
}
