import React from "react";
import {SortedListAbsenceResponse} from "../../../../store/absenceCalendar/actions/AbsenceCalendarActionTypes";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import {useAbsenceReportDownload} from "../Hooks/useAbsenceReportDownload";

function AbsenceCalendarDefaultActions({response, createAbsence, requestHoliday}: Props) {
    const {downloadYearlyAbsenceReport, generateYearlyAbsenceReport} = useAbsenceReportDownload();
    return (
        <React.Fragment>
            <div className="row mt-3 ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Request Annual Leave"}
                        onClick={requestHoliday}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <React.Fragment>
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Create Leave"}
                                onClick={createAbsence}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            {response.years && response.years.length > 0 ? (
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Download Report"}
                                    onClick={async () => {
                                        const {years} = response;
                                        if (!years?.length) return;
                                        const report = await generateYearlyAbsenceReport(
                                            response.absences,
                                            years[0]
                                        );

                                        downloadYearlyAbsenceReport(report);
                                    }}
                                    colour={ButtonColourOptions.Yellow}
                                    roundedCorner
                                />
                            ) : null}
                        </React.Fragment>
                    </AuthAmI>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AbsenceCalendarDefaultActions;

interface Props {
    response: SortedListAbsenceResponse;
    requestHoliday: () => void;
    createAbsence: () => void;
}
