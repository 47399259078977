import React, {useEffect} from "react";
import {DateRange} from "../../utils/filterUtils";
import {useDateFilter} from "./Hooks/useDateFilter";
import PulseMonthPicker from "../DatePicker/PulseMonthPicker";
import useUnixUtils from "../Hooks/useUnixUtils";
import FilterContainer from "../Filters/FilterContainer";

function DateFilter({onChange, displayType, label}: Props) {
    const {dateRange, updateRange} = useDateFilter();
    const {dateToMoment} = useUnixUtils();

    useEffect(() => {
        onChange(dateRange);
    }, [dateRange]);

    return (
        <React.Fragment>
            {displayType === "Small" ? (
                <React.Fragment>
                    <div className="width-250px">
                        <PulseMonthPicker
                            initialDate={dateRange.startDate}
                            onChange={(item) => {
                                if (!item) return;
                                const start = dateToMoment(item).startOf("month").unix();
                                const end = dateToMoment(item).endOf("month").unix();

                                updateRange({
                                    ...dateRange,
                                    startDate: start,
                                    endDate: end
                                });
                            }}
                        />
                    </div>
                </React.Fragment>
            ) : null}
            {displayType === "Large" ? (
                <FilterContainer closed={false}>
                    <table className="filters-table mt-3">
                        <tbody className="filters-lg">
                            <tr>
                                <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                            </tr>
                            <tr>
                                <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                    <PulseMonthPicker
                                        initialDate={dateRange.startDate}
                                        onChange={(item) => {
                                            if (!item) return;
                                            const start = dateToMoment(item)
                                                .startOf("month")
                                                .unix();
                                            const end = dateToMoment(item).endOf("month").unix();

                                            updateRange({
                                                ...dateRange,
                                                startDate: start,
                                                endDate: end
                                            });
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody className="filters-md">
                            <tr>
                                <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                            </tr>
                            <tr>
                                <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                    <PulseMonthPicker
                                        initialDate={dateRange.startDate}
                                        onChange={(item) => {
                                            if (!item) return;
                                            const start = dateToMoment(item)
                                                .startOf("month")
                                                .unix();
                                            const end = dateToMoment(item).endOf("month").unix();

                                            updateRange({
                                                ...dateRange,
                                                startDate: start,
                                                endDate: end
                                            });
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody className="filters-sm">
                            <tr>
                                <th className="filter-heading w-100 pl-3 pr-3">Date</th>
                            </tr>
                            <tr>
                                <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                                    <PulseMonthPicker
                                        initialDate={dateRange.startDate}
                                        onChange={(item) => {
                                            if (!item) return;
                                            const start = dateToMoment(item)
                                                .startOf("month")
                                                .unix();
                                            const end = dateToMoment(item).endOf("month").unix();

                                            updateRange({
                                                ...dateRange,
                                                startDate: start,
                                                endDate: end
                                            });
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </FilterContainer>
            ) : null}
        </React.Fragment>
    );
}

export default DateFilter;

interface Props {
    onChange: (range: DateRange) => void;
    displayType: DisplayType;
    label?: string;
}

type DisplayType = "Large" | "Small";
