import React from "react";
import {WithServiceState} from "store-fetch-wrappers";
import HolidayListWrapper from "./Components/HolidayListWrapper";
import {useHolidayListPage} from "./Hooks/useHolidayListPage";

const ServiceWrapper = WithServiceState(HolidayListWrapper);

const HolidayList = () => {
    const {store} = useHolidayListPage();

    return (
        <React.Fragment>
            <div className="page-container pt-2">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    {...store}
                />
            </div>
        </React.Fragment>
    );
};

export default HolidayList;
