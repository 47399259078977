import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const REQUEST_ABSENCE_STORE = createStoreState("request_absence");

interface RequestAbsenceLoading extends StoreServiceData<string> {
    type: typeof REQUEST_ABSENCE_STORE.LOADING;
}

interface RequestAbsenceError extends StoreServiceData<string> {
    type: typeof REQUEST_ABSENCE_STORE.ERROR;
}

interface RequestAbsenceSuccess extends StoreServiceData<string> {
    type: typeof REQUEST_ABSENCE_STORE.SUCCESS;
}

export type RequestAbsenceDispatchTypes =
    | RequestAbsenceLoading
    | RequestAbsenceError
    | RequestAbsenceSuccess;
