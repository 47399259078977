import {AbsenceApi, UsersApi} from "../../api/staff";

export default class StaffApiModel {
    private static _instance: StaffApiModel;
    private static _usersApi: UsersApi;
    private static _absenceApi: AbsenceApi;

    private constructor() {
        if (StaffApiModel._instance) {
            throw new Error("Use StaffApiModel.instance instead of new");
        }
        StaffApiModel._instance = this;
    }

    static get instance(): StaffApiModel {
        return StaffApiModel.instance ?? (StaffApiModel._instance = new StaffApiModel());
    }

    static get usersApi(): UsersApi {
        if (!StaffApiModel._usersApi) {
            StaffApiModel._usersApi = new UsersApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel._usersApi;
    }

    static get absenceApi(): AbsenceApi {
        if (!StaffApiModel._absenceApi) {
            StaffApiModel._absenceApi = new AbsenceApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel._absenceApi;
    }
}
