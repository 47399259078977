import React from "react";
import {UserAbsenceRequest, UserAbsenceRequestStatus} from "../../../../api/staff";
import FormRow from "../../../Form/FormRow";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHolidayRequest} from "../Hooks/useHolidayRequest";
import {Loading} from "store-fetch-wrappers";
import AbsenceApproval, {Approval} from "../../../AbsenceApprovalForm/AbsenceApproval";
import {HolidayView} from "./HolidayListActions";

function HolidayRequestForm({item, onFormClosed}: Props) {
    const {userAbsenceStore, goToConfigureStaffAbsenceConfig} = useHolidayRequest();
    return (
        <React.Fragment>
            <FormRow rowName={"Name"}>
                <p className="mb-0">{item.name}</p>
            </FormRow>
            <FormRow rowName={"Request Stats"}>
                <p className="mb-0">{getUiFriendlyText(item.status)}</p>
            </FormRow>
            <FormRow rowName={"Comments"}>
                <p className="mb-0">{item.reason || "N/A"}</p>
            </FormRow>
            <FormRow rowName={"Annual Leave Start Date"}>
                <p className="mb-0">{formatUnixToDDMMYYYY(item.startDate)}</p>
            </FormRow>
            <FormRow rowName={"Annual Leave Finish Date"}>
                <p className="mb-0">{formatUnixToDDMMYYYY(item.endDate)}</p>
            </FormRow>
            <FormActionContainer withColumn>
                <React.Fragment>
                    {item.status === UserAbsenceRequestStatus.PendingApproval ? (
                        <React.Fragment>
                            {userAbsenceStore.loading ? (
                                <Loading type={"three-ring"} showLoadingText={false} />
                            ) : (
                                <React.Fragment>
                                    <AbsenceApproval
                                        view={HolidayView.Calendar}
                                        innerValue={"Approve Annual Leave"}
                                        buttonColour={ButtonColourOptions.Yellow}
                                        modalTitle={"Approve Annual Leave"}
                                        absenceRequest={item}
                                        approvalType={Approval.Accept}
                                        onFormClosed={onFormClosed}
                                    />
                                    <AbsenceApproval
                                        view={HolidayView.Calendar}
                                        innerValue={"Decline Annual Leave"}
                                        buttonColour={ButtonColourOptions.DarkBlue}
                                        modalTitle={"Decline Annual Leave"}
                                        absenceRequest={item}
                                        approvalType={Approval.Reject}
                                        onFormClosed={onFormClosed}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : null}
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Configure Staff Absence Config"}
                        onClick={() => goToConfigureStaffAbsenceConfig(item)}
                        colour={ButtonColourOptions.Orange}
                        roundedCorner
                    />
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
}

export default HolidayRequestForm;

interface Props {
    onFormClosed: () => void;
    item: UserAbsenceRequest;
}
