import {StaffAccessLevel, UserData} from "../../../api/staff";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {McConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

export function useRouteSetup() {
    const dispatch = useDispatch();

    function setRoutes(user: UserData, config: McConfig): void {
        const routes = getUserRoutes(user, config);

        dispatch(setNavigationItems(routes));
    }

    function getUserRoutes(user: UserData, config: McConfig): NavigationItem[] {
        const accessLevel = getUserAccessLevel(user, config);
        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return [
                    {
                        name: routeNames.absenceOverview.name,
                        path: routeNames.absenceOverview.path
                    },
                    {
                        name: routeNames.absenceCalendar.name,
                        path: routeNames.absenceCalendar.path
                    },
                    {
                        name: routeNames.holidayRequestAbsenceList.name,
                        path: routeNames.holidayRequestAbsenceList.path
                    }
                ];
            default:
                return [
                    {
                        name: routeNames.absenceCalendar.name,
                        path: routeNames.absenceCalendar.path
                    },
                    {
                        name: routeNames.holidayRequestAbsenceList.name,
                        path: routeNames.holidayRequestAbsenceList.path
                    }
                ];
        }
    }

    return {
        setRoutes
    };
}
