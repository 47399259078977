import React from "react";
import AbsenceCalendarWrapper from "./Components/AbsenceCalendarWrapper";
import {WithServiceState} from "store-fetch-wrappers";
import {useAbsenceCalendar} from "./Hooks/useAbsenceCalendar";
const ServiceWrapper = WithServiceState(AbsenceCalendarWrapper);

function AbsenceCalendar() {
    const {absenceCalendarStore} = useAbsenceCalendar();
    return (
        <React.Fragment>
            <div className="page-container pt-3">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...absenceCalendarStore}
                />
            </div>
        </React.Fragment>
    );
}

export default AbsenceCalendar;
