import {Dispatch} from "redux";
import {REQUEST_ABSENCE_STORE, RequestAbsenceDispatchTypes} from "./RequestAbsenceActionTypes";
import {UserAbsenceInitialRequest} from "../../../api/staff";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export function nullifyRequestTimeOffAbsenceStore() {
    return async (dispatch: Dispatch<RequestAbsenceDispatchTypes>) => {
        dispatch({
            type: REQUEST_ABSENCE_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
}

export function saveAbsenceRequest(req: UserAbsenceInitialRequest) {
    return async (dispatch: Dispatch<RequestAbsenceDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                REQUEST_ABSENCE_STORE,
                dispatch,
                () => StaffApiModel.absenceApi.requestAbsence(req),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REQUEST_ABSENCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}
