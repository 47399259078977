import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {UserData} from "../../../api/staff";
import {STAFF_LIST_STORE_STATE} from "../actions/StaffListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<UserData[]>([]);

/** Infer type of the reducer for code completion and quality */
const staffListReducer = (
    state: StoreServiceData<UserData[]> = defaultState,
    action: ServiceActionTypes<UserData[]>
): StoreServiceData<UserData[]> =>
    createReducer(state, action, STAFF_LIST_STORE_STATE, () => showErrorToast(action.error));

export default staffListReducer;
