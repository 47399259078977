import React from "react";
import HalfDonut from "./HalfDonut";
import Bar from "./Bar";
import {StatisticsItem} from "./Helpers/statisticsHelpers";

const StatsItem = (props: StatisticsItem) => {
    return (
        <div className="stats-item">
            <HalfDonut {...props} />
            <Bar {...props} />
        </div>
    );
};

export default StatsItem;
