import React from "react";
import {SortedListAbsenceResponse} from "../../../../store/absenceCalendar/actions/AbsenceCalendarActionTypes";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import {useAbsenceCalendarView} from "../Hooks/useAbsenceCalendarView";
import {toArray} from "../../../../utils/sortingUtils";
import AbsenceCalendarYearActions from "./AbsenceCalendarYearActions";
import AbsenceCalendarDefaultActions from "./AbsenceCalendarDefaultActions";

function AbsenceCalendarView({initialDate, response}: AbsenceCalendarViewProps) {
    const {
        calendarRef,
        viewAbsence,
        renderEventContent,
        createAbsence,
        requestHoliday,
        isJanuary,
        isDecember,
        previousMonth,
        nextMonth,
        previousMonthText,
        nextMonthText,
        currentMonthText
    } = useAbsenceCalendarView({
        initialDate,
        response
    });
    return (
        <React.Fragment>
            <div className="calendar-wrapper mb-4">
                <div className="full-calendar-wrapper">
                    <AbsenceCalendarDefaultActions
                        createAbsence={createAbsence}
                        response={response}
                        requestHoliday={requestHoliday}
                    />
                    {response.years && response.years.length > 0 ? (
                        <AbsenceCalendarYearActions
                            currentMonthText={currentMonthText || ""}
                            nextMonth={nextMonth}
                            nextMonthText={nextMonthText || ""}
                            isJanuary={isJanuary}
                            isDecember={isDecember}
                            previousMonth={previousMonth}
                            previousMonthText={previousMonthText || ""}
                        />
                    ) : null}

                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{left: "", center: "", right: ""}}
                        initialView="dayGridMonth"
                        selectable={true}
                        firstDay={1}
                        eventOrder="color"
                        longPressDelay={500}
                        events={toArray(response.sortedAbsenceItems)}
                        initialDate={initialDate * 1000}
                        eventContent={renderEventContent}
                        // dateClick={createNewEvent}
                        eventClick={viewAbsence}
                        contentHeight={1100}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default AbsenceCalendarView;

export interface AbsenceCalendarViewProps {
    response: SortedListAbsenceResponse;
    initialDate: number;
}
