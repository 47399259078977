import React, {useEffect, useState} from "react";
import {SortedListAbsenceResponse} from "../../../../store/absenceCalendar/actions/AbsenceCalendarActionTypes";
import {useDispatch} from "react-redux";
import {ListAbsencesRequest} from "../../../../api/staff";
import {
    getAbsencesForCalendar,
    nullifyAbsenceCalendarStore
} from "../../../../store/absenceCalendar/actions/AbsenceCalendarActions";
import FilterContainer from "../../../Filters/FilterContainer";
import AbsenceCalendarFilters from "./AbsenceCalendarFilters";
import AbsenceCalendarView from "./AbsenceCalendarView";
import moment from "moment";

function AbsenceCalendarWrapper(props: SortedListAbsenceResponse) {
    const dispatch = useDispatch();
    const [request, setRequest] = useState<ListAbsencesRequest>();

    useEffect(() => {
        dispatch(nullifyAbsenceCalendarStore());
    }, []);

    function onRequestChanged(entity: ListAbsencesRequest) {
        if (JSON.stringify(entity) === JSON.stringify(request)) return;
        setRequest(entity);
        dispatch(getAbsencesForCalendar(entity));
    }

    function getInitialDate() {
        if (!request) return moment().unix();

        if (request.startDate) return request.startDate;

        const month = moment().month();

        if (request.years) {
            return moment().set({year: request.years[0], month}).startOf("month").unix();
        }

        return moment().unix();
    }

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <AbsenceCalendarFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            {request ? (
                <AbsenceCalendarView response={props} initialDate={getInitialDate()} />
            ) : null}
        </React.Fragment>
    );
}

export default AbsenceCalendarWrapper;
