import React, {useEffect, useState} from "react";
import DatePickerInputButton from "../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";

function PulseMonthPicker({onChange, initialDate, inline, minDate, className}: Props) {
    const [startDate, setStartDate] = useState<Moment | null>(
        initialDate ? moment.unix(initialDate) : null
    );

    useEffect(() => {
        setStartDate(initialDate ? moment.unix(initialDate) : null);
    }, [initialDate]);
    return (
        <React.Fragment>
            <DatePicker
                selected={startDate?.toDate()}
                onChange={(value) => {
                    setStartDate(moment(value).startOf("month") || null);
                    onChange(value);
                }}
                showMonthYearPicker
                dateFormat="MMMM yyyy"
                portalId="root-portal"
                customInput={<DatePickerInputButton />}
                inline={inline}
                minDate={minDate}
                className={className}
            />
        </React.Fragment>
    );
}

export default PulseMonthPicker;

interface Props {
    onChange: (value: Date | null) => void;
    initialDate?: number;
    inline?: boolean;
    minDate?: Date | undefined | null;
    className?: string;
}
