import {MonthWithStartEnd, useMonthInfo} from "../../../MonthInfo/Hooks/useMonthInfo";
import {AbsenceType, UserAbsence} from "../../../../api/staff";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {useUserAbsence} from "../../../Hooks/useUserAbsence";
import {jsonToCsv} from "../../../../utils/csvUtils";
import {useYearlyAbsenceReportCSV} from "./useYearlyAbsenceReportCSV";

export function useAbsenceReportDownload() {
    const {getMonthStartAndEndTimestampForYear} = useMonthInfo();
    const {getNumberOfDaysBetweenDays} = useUserAbsence();
    const {generateDownloadableCSVForReport} = useYearlyAbsenceReportCSV();

    function downloadYearlyAbsenceReport(report: AbsenceYearlyReport) {
        const filename = `Absence Report - Medicare Group UK - ${report.year}`;
        const csvPayload = generateDownloadableCSVForReport(report);
        jsonToCsv(filename, csvPayload);
    }

    function generateYearlyAbsenceReport(
        absences: UserAbsence[],
        year: number
    ): AbsenceYearlyReport {
        const monthInfo = getMonthStartAndEndTimestampForYear(year);

        const monthAbsences: MonthAbsences[] = [];

        for (const info of monthInfo) {
            //Filter By Month
            const filtered = absences.filter((item) => getAbsenceForMonth(item, info));

            monthAbsences.push({
                name: info.monthName,
                groupings: getAbsenceInfoForMonthGroups(filtered)
            });
        }

        return {
            monthAbsences,
            year
        };
    }

    function getAbsenceInfoForMonthGroups(absences: UserAbsence[]): AbsenceGroup[] {
        return Object.keys(AbsenceType).map((key) => {
            const type = getAbsenceTypeFromString(key);
            const filteredAbsences = absences
                .sort((a, b) => a.startDate - b.startDate)
                .map((item) => {
                    if (type === item.type) return mapUserAbsenceToUserAbsenceInfo(item);
                    return undefined;
                });

            return {
                type,
                absences: removeUndefinedValuesFromArray(filteredAbsences)
            };
        });
    }

    function removeUndefinedValuesFromArray<T>(array: T[]): T[] {
        return array.filter((it) => it !== undefined);
    }

    function mapUserAbsenceToUserAbsenceInfo({
        startDate,
        endDate,
        name,
        reason,
        numberDays,
        type
    }: UserAbsence): UserAbsenceInfo {
        return {
            start: formatUnixToDDMMYYYY(startDate),
            end: formatUnixToDDMMYYYY(endDate),
            name: name || "Name not specified",
            reason: reason || "Reason not specified",
            absenceDuration: numberDays || getNumberOfDaysBetweenDays(startDate, endDate),
            type
        };
    }

    function getAbsenceTypeFromString(value: string): AbsenceType {
        return AbsenceType[value as keyof typeof AbsenceType];
    }

    function getAbsenceForMonth(
        absence: UserAbsence,
        currentMonth: MonthWithStartEnd
    ): UserAbsence | undefined {
        // In previous month and current
        if (absence.startDate < currentMonth.start && absence.endDate >= currentMonth.start) {
            return absence;
        }

        // In current month and next
        if (absence.startDate <= currentMonth.end && absence.endDate > currentMonth.end) {
            return absence;
        }

        // Absence goes through month (Count towards absence for that month)
        if (absence.startDate < currentMonth.start && absence.endDate > currentMonth.end) {
            return absence;
        }

        // Absence exists within the month
        if (absence.startDate >= currentMonth.start && absence.endDate <= currentMonth.end) {
            return absence;
        }
    }

    return {
        downloadYearlyAbsenceReport,
        generateYearlyAbsenceReport
    };
}

export interface AbsenceYearlyReport {
    year: number;
    monthAbsences: MonthAbsences[];
}

export interface MonthAbsences {
    name: string;
    groupings: AbsenceGroup[];
}

export interface AbsenceGroup {
    type: AbsenceType;
    absences: (UserAbsenceInfo | undefined)[];
}

export interface UserAbsenceInfo {
    name: string;
    reason: string;
    start: string;
    end: string;
    absenceDuration: number;
    type: AbsenceType;
}
