import React from "react";
import {useRequestTimeOffForm} from "../Hooks/useRequestTimeOffForm";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import useUnixUtils from "../../../Hooks/useUnixUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {showSuccessToast} from "../../../../utils/toastUtils";
import FormActionContainer from "../../../Form/FormActionContainer";
import {Loading} from "store-fetch-wrappers";
import PulseDatePicker from "../../../DatePicker/PulseDatePicker";

function RequestTimeOffForm() {
    const {unixToDate, dateToMoment} = useUnixUtils();
    const {request, updateRequest, save, store, backToAbsenceCalendar, getAbsenceLength} =
        useRequestTimeOffForm();
    return (
        <React.Fragment>
            <FormHeader headerName={"Request Annual Leave"} />
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    style={{minHeight: `100px`}}
                    className="input-fields text-area-inputs"
                    element={"textarea"}
                    placeholder={"Enter comments here..."}
                    value={request.reason}
                    debounceTimeout={300}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateRequest({
                            ...request,
                            reason: value
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Annual Leave Start Date"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseDatePicker
                    initialDate={request.startDate}
                    onChange={(date) => {
                        if (!date) return;
                        const startOfDay = dateToMoment(date).clone().startOf("day");
                        updateRequest({
                            ...request,
                            startDate: startOfDay.unix()
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Annual Leave Finish Date"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseDatePicker
                    initialDate={request.endDate}
                    onChange={(date) => {
                        if (!date) return;
                        const endOfDay = dateToMoment(date).clone().endOf("day");
                        updateRequest({
                            ...request,
                            endDate: endOfDay.unix()
                        });
                    }}
                    minDate={unixToDate(request.startDate)}
                />
            </FormRow>
            <FormRow rowName={"Duration of Annual Leave"}>
                <p className="mb-0">{getAbsenceLength()}</p>
            </FormRow>
            {store.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={async () => {
                            const success = await save();

                            if (!success) return;
                            showSuccessToast(`Success! Absence requested`);
                            backToAbsenceCalendar();
                        }}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={backToAbsenceCalendar}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            )}
        </React.Fragment>
    );
}

export default RequestTimeOffForm;
