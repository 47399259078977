import {useEffect, useState} from "react";
import {ListUserAbsenceRequests, StaffAccessLevel} from "../../../../api/staff";
import moment from "moment";
import {useQuery} from "../../../Hooks/useQuery";
import {useHistory} from "react-router-dom";
import {useUserData} from "../../../Hooks/useUserData";

export function useHolidayListFilters() {
    const [request, setRequest] = useState<ListUserAbsenceRequests>();
    const query = useQuery();
    const history = useHistory();
    const user = useUserData();

    useEffect(() => {
        const startDate = query.get("startDate");
        const endDate = query.get("endDate");
        const usernames = query.get("usernames");

        updateRequest({
            startDate: startDate ? +startDate : moment().startOf("month").unix(),
            endDate: endDate ? +endDate : moment().endOf("month").unix(),
            usernames: usernames ? getUsernamesFromQuery(usernames) : undefined
        });
    }, []);

    function updateRequest(newReq: ListUserAbsenceRequests): void {
        setRequest(newReq);
        buildRequestUrl(newReq);
    }

    function buildRequestUrl(entity: ListUserAbsenceRequests) {
        const search = getQueryStringsFromObject(entity);

        history.push({
            search
        });
    }

    function getUsernamesFromQuery(value?: string): string[] | undefined {
        if (!value) return;

        return value.split(",");
    }

    function getUsernameFromRequest(): string | undefined {
        if (!request) return;
        if (!request.usernames) return;

        return request.usernames[0];
    }

    function getQueryStringsFromObject(entity: ListUserAbsenceRequests): string {
        switch (user.accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                const userQuery = entity.usernames?.length
                    ? `&usernames=${entity.usernames[0]}`
                    : "";
                return `startDate=${entity.startDate}&endDate=${entity.endDate}${userQuery}`;
            default:
                return `startDate=${entity.startDate}&endDate=${entity.endDate}`;
        }
    }

    return {
        request,
        updateRequest,
        getUsernameFromRequest
    };
}
