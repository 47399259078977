import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {REQUEST_ABSENCE_STORE} from "../actions/RequestAbsenceActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const requestAbsenceReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
) => createReducer(state, action, REQUEST_ABSENCE_STORE, () => showErrorToast(action.error));

export default requestAbsenceReducer;
