import React from "react";

export function useFullCalendar() {
    function renderEventContent(eventInfo: any) {
        return (
            <div
                className="custom-event-tab w-100 cursor-pointer"
                style={{background: eventInfo.backgroundColor, border: "none"}}
            >
                <p
                    className="calendar-item pl-1 mb-0 text-wrap"
                    style={{color: eventInfo.textColor}}
                >
                    {eventInfo.event.title}
                </p>
            </div>
        );
    }

    return {
        renderEventContent
    };
}
