import React from "react";
import {SortedListHolidayResponse} from "../../../../store/holidayRequestAbsenceList/actions/HolidayRequestAbsenceListActionTypes";
import {useHolidayListCalendarView} from "../Hooks/useHolidayListCalendarView";
import {toArray} from "../../../../utils/sortingUtils";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import {Modal} from "pulse-modal";
import HolidayRequestForm from "./HolidayRequestForm";

function HolidayListCalendarView({response, initialDate}: HolidayCalendarViewProps) {
    const {
        calendarRef,
        renderEventContent,
        viewHolidayRequest,
        isShown,
        selectedRequest,
        toggle,
        onModalClose
    } = useHolidayListCalendarView({
        response,
        initialDate
    });
    return (
        <React.Fragment>
            <div className="calendar-wrapper mb-4">
                <div className="full-calendar-wrapper">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        headerToolbar={{left: "", center: "", right: ""}}
                        initialView="dayGridMonth"
                        selectable={true}
                        firstDay={1}
                        eventOrder="color"
                        longPressDelay={500}
                        events={toArray(response.sortedHolidayItems)}
                        initialDate={initialDate * 1000}
                        eventContent={renderEventContent}
                        eventClick={viewHolidayRequest}
                        contentHeight={1100}
                    />
                </div>
            </div>
            <Modal
                modalSize={"lg"}
                title={"Annual Leave Request"}
                bodyChildren={
                    <React.Fragment>
                        {selectedRequest && (
                            <HolidayRequestForm
                                item={selectedRequest}
                                onFormClosed={onModalClose}
                            />
                        )}
                    </React.Fragment>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
}

export default HolidayListCalendarView;

export interface HolidayCalendarViewProps {
    response: SortedListHolidayResponse;
    initialDate: number;
}
