import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {getAllMedicareStaffMembers} from "../../../../store/staffList/actions/StaffListActions";
import {useRouteSetup} from "../../Hooks/useRouteSetup";
import {useUserData} from "../../../Hooks/useUserData";
import useMcConfig from "../../../Hooks/useMcConfig";

export function useHolidayListPage() {
    const {config} = useMcConfig();
    const dispatch = useDispatch();
    const store = useSelector((state: RootStore) => state.holidayRequestAbsenceList);
    const {setRoutes} = useRouteSetup();
    const user = useUserData();

    useEffect(() => {
        //Set Nav Items
        dispatch(getAllMedicareStaffMembers());
    }, []);

    useEffect(() => {
        if (!config.auth) return;
        if (user.username.length === 0) return;
        setRoutes(user, config);
    }, [user, config]);

    return {
        store
    };
}
